import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import withSubitemLabels from 'hoc/withSubitemLabels';

import { useAppSelector } from '../../store/hooks';
import MenuItem from './MenuItem';
import { IMenuItem } from '../Types';
import UserInfo from '../UserInfo';
import ShortcutsMenuItem from './ShortcutsMenuItem';

interface IProps {
  items: Array<IMenuItem>;
}

const getLastItem = (data: string[] | string) => data[data.length - 1];

const checkIfSubRouteIsActive = (items: IMenuItem[], path: string): boolean => {
  return items.some(({ url }) => {
    const splittedUrl = url.split('/');
    const splittedPathname = path.split('/');
    const isSubRouteMatched =
      splittedUrl.length && splittedPathname.length && getLastItem(splittedUrl) === getLastItem(splittedPathname);
    return isSubRouteMatched || path.includes('edit');
  });
};

const checkIfSameRoute = (items, path): boolean => {
  return items.some(({ url, subItems }) => {
    const splittedUrl = url.split('/');
    const splittedPathname = path.split('/');
    const isRouteMatched =
      splittedUrl.length && splittedPathname.length && getLastItem(splittedUrl) === getLastItem(splittedPathname);
    if (!isRouteMatched && subItems) {
      return checkIfSubRouteIsActive(subItems, path);
    }
    return isRouteMatched;
  });
};

const Menu: FC<IProps> = ({ items }) => {
  const name = useAppSelector((state) => state.auth.userName);
  const { pathname } = useLocation();

  const isSubMenuActive = ({ items }: IMenuItem): boolean => {
    let isActive = false;

    if (items.length) {
      isActive = checkIfSameRoute(items, pathname);
    }
    return isActive;
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink to="/dashboard" className="brand-link">
          <img src="/img/gc-blue-144.png" alt="Gelato Logo" className="brand-image" style={{ opacity: '.8' }} />
          <span className="brand-text font-weight-light">Gelato Network</span>
        </NavLink>
        <div className="sidebar">
          {name && <UserInfo name={name} />}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent text-sm" role="menu">
              {items.map((item, index) => (
                <MenuItem key={`menu-${index}`} item={item} active={isSubMenuActive(item)} />
              ))}
              <ShortcutsMenuItem />
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default withSubitemLabels(Menu);
