import React, { CSSProperties, ErrorInfo } from 'react';

import { APP_CRASHED, eventTrack } from 'eventTracking';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error;
}

const buttonStyle: CSSProperties = {
  cursor: 'pointer',
  outline: 0,
  color: '#fff',
  backgroundColor: '#0d6efd',
  borderColor: '#0d6efd',
  display: 'inline-block',
  fontWeight: 400,
  lineHeight: 1.5,
  textAlign: 'center',
  border: '1px solid transparent',
  padding: '6px 12px',
  fontSize: '16px',
  borderRadius: '.25rem',
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Update state to indicate that an error occurred
    this.setState({ hasError: true, error });
    console.error({ error, errorInfo }); //
    eventTrack(APP_CRASHED, {
      error: error?.message,
      stack: error?.stack,
      errorInfo: errorInfo?.componentStack,
      url: window?.location?.href,
    });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      // Render fallback UI when an error occurs
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            background: '#f4f6f9',
          }}
        >
          <div style={{ background: '#fff', padding: '20px', marginTop: '50px' }}>
            <h2>Something went wrong!</h2>
            <br />
            <p>
              Please try clearing the cache and then reload the page.
              <br />
              <small>- on Windows: Ctrl + Shift + R</small>
              <br />
              <small>- on Mac: Command + Shift + R or Command + Option + R</small>
            </p>
            <p>If problem persists, please reach out to Gelato support.</p>
            <br />
            <button style={buttonStyle} onClick={() => window.location.reload()}>
              Reload
            </button>

            <details style={{ marginTop: '20px', maxWidth: '60vw', overflow: 'auto' }}>
              <summary>Details</summary>
              <pre>
                <div>{error?.message}</div>
                <div>{error?.stack}</div>
              </pre>
            </details>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
