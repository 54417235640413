/**
 * News feed
 */
export const MESSAGES = '/notifications';
export const MESSAGES_LIST = '/notifications/list';
export const BANNERS = '/banners';
export const BANNERS_LIST = '/banners/list';

/**
 * Identity
 */
export const ROLES = '/proxy/identity/v3/user-roles';

/**
 * Machine
 */

export const MACHINE_LIST = '/x/machines';
export const MACHINE_LOGS = '/x/machines/{id}/logs';
export const MACHINE_LOG_FILE = '/x/machines/{machineId}/logs/{logId}/files/{fileId}/download?noredir';
export const MACHINE_REQUEST_LOG = '/x/machines/{machineId}/logs/request';
export const MACHINE_SUPPORTED_VERSION = '/x/machines/supported-versions';
export const MACHINE_SUPPORTED_VERSION_UPDATE = '/x/machines/supported-versions/{appType}/{version}';

/**
 * Print partner activity
 */
export const PRINT_PARTNER_ACTIVITY = '/admin/print-partner-activity';
export const PRINT_PARTNER_ACTIVITY_STATUS = '/admin/print-partner-activity/data-fetch-status';
export const PRINT_PARTNER_ACTIVITY_LOGS = '/admin/print-partner-activity/{partnerIdentifier}/logs';

/**
 * Staged rollout
 */
export const STAGED_ROLLOUT = '/admin/staged-rollout';

/**
 * Sync feature toggles
 */
export const SYNC_FEATURE_TOGGLES = '/x/sync-firebase';

/**
 * Commands
 */
export const COMMANDS_LIST = '/admin/commands';
export const COMMAND = '/admin/commands/{id}';
