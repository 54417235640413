import { FC } from 'react';

interface IProps {
  name: string;
  src?: string;
}

const UserInfo: FC<IProps> = ({ name, src }) => {
  return (
    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
        <img src={src} className="img-circle elevation-2" alt="User" />
      </div>
      <div className="info text-gray">{name}</div>
    </div>
  );
};

UserInfo.defaultProps = {
  src: '/img/avatar5.png',
};

export default UserInfo;
