import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryGNXWrapper } from 'core/Helpers/baseQuery';

import { REACT_APP_GNX_URL } from 'config/DomainsConfig';
import { STAGED_ROLLOUT } from 'config/GNXEndPoints';

import { StagedRolloutResponse, UpdateStagedRolloutRequest } from './Types';

export const StagedRolloutApi = createApi({
  reducerPath: 'StagedRolloutApi',
  baseQuery: fetchBaseQueryGNXWrapper(REACT_APP_GNX_URL),
  endpoints: (builder) => ({
    getStagedRollout: builder.query<StagedRolloutResponse, void>({
      query: () => ({
        url: STAGED_ROLLOUT,
        method: 'GET',
      }),
      transformResponse: ({ data }: { data: StagedRolloutResponse }) => data,
    }),
    updateStagedRollout: builder.mutation<void, UpdateStagedRolloutRequest>({
      query: (data: UpdateStagedRolloutRequest) => ({
        url: STAGED_ROLLOUT,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetStagedRolloutQuery, useUpdateStagedRolloutMutation } = StagedRolloutApi;
