import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { APPAREL_MANAGEMENT_URL } from 'config/DomainsConfig';
import {
  GET_FETCH_PRODUCTS_FROM_PP_STATUS,
  GET_PARTNER_CONNECT_API_SYNC_STATUS,
  GET_PARTNER_CONNECT_API_UNSYNCED_CHANGES,
  TRIGGER_FETCH_PRODUCTS_FROM_PP,
  GET_PARTNER_SKU_STATISTICS,
  UPDATE_PARTNER_CONNECT_API_SYNC_STATUS,
  UPDATE_PARTNER_SKU_MAPPING,
  GET_APPAREL_CHANGE_HISTORY_LOGS,
  GENERATE_CHANGE_HISTORY_CSV,
  GET_PARTNER_PENDING_SKU_UPDATES,
  GET_CONFIG_CAT_SETTINGS_APPAREL,
  GET_PARTNER_SKU_MAPPING_LIST_DETAILS,
  GET_MANUFACTURER_SKU_MAPPING_LIST,
  GET_MANUFACTURER_SKU_UN_MAPPED_LIST,
} from 'config/GelatoNetworkEndPoints';
import { FetchStatus } from 'containers/PrintHouseManagement/ApparelManagement/components/FetchProductsFromPP/enum';

import {
  IPartnerStatistics,
  IPartnerSettingRequestBody,
  IPartnerSettingsRequest,
  IPartnerSettingsData,
  IPartnerUnSyncedChanges,
  ISKUMappingResponse,
  IUnsyncedChange,
  IUpdateSKUDataRequestBody,
  ITriggerFetchProductsRequest,
  ITriggerFetchProductsResponse,
  IChangeHistoryRequestBody,
  IApparelChangeHistory,
  IHistoryLog,
  IPaginationMeta,
  IGenerateCSV,
  IPartnerSettingsUpdateResponse,
  IUnPublishedSkuUpdate,
  IPendingSkuUpdate,
  IConfigCatResponse,
  IPartnerSkuMappingRequest,
  IPartnerSkuMappingResponse,
  IManufacturerSkuMappingListRequest,
  IManufacturerSkuMappingListResponse,
  IManufacturerSkuUnMappedListResponse,
  IManufacturerSkuUnMappedListRequest,
} from './Types';

interface IPartnerSKUMappingRequestBody {
  partnerUids: string[];
}

export const ApparelManagementApi = createApi({
  reducerPath: 'ApparelManagementApi',
  baseQuery: fetchBaseQueryWrapper(APPAREL_MANAGEMENT_URL),
  tagTypes: ['ApparelManagement'],
  endpoints: (builder) => ({
    getConnectApiSyncStatus: builder.query<IPartnerSettingsData, IPartnerSKUMappingRequestBody>({
      query: (data) => ({
        url: GET_PARTNER_CONNECT_API_SYNC_STATUS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { partnerSettings: IPartnerSettingsData } }) => {
        return {
          ...response.data.partnerSettings[0],
          printLocations: response.data.partnerSettings[0]?.printLocations || [],
        };
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'Sync' }],
    }),
    getConnectApiUnSyncedChanges: builder.query<IUnsyncedChange[], IPartnerSKUMappingRequestBody>({
      query: (data) => ({
        url: GET_PARTNER_CONNECT_API_UNSYNCED_CHANGES,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { pendingMappingUpdates: IPartnerUnSyncedChanges } }) => {
        return response.data.pendingMappingUpdates[0].changes || [];
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'UnSyncedChanges' }],
    }),
    getPendingSkuUpdates: builder.query<IPendingSkuUpdate[], IPartnerSKUMappingRequestBody>({
      query: (data) => ({
        url: GET_PARTNER_PENDING_SKU_UPDATES,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { pendingPartnerSkuUpdates: IPendingSkuUpdate[] } }) => {
        return response.data.pendingPartnerSkuUpdates || [];
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'PendingUpdates' }],
    }),
    getPartnerSkuStatistics: builder.query<IPartnerStatistics, IPartnerSettingRequestBody>({
      query: (data) => ({
        url: GET_PARTNER_SKU_STATISTICS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: IPartnerStatistics }) => {
        return response.data;
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'UnSyncedChanges' }],
    }),
    getApparelChangeHistory: builder.query<IApparelChangeHistory, IChangeHistoryRequestBody>({
      query: (data) => ({
        url: GET_APPAREL_CHANGE_HISTORY_LOGS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { meta: IPaginationMeta; updates: IHistoryLog[] } }) => {
        return response.data;
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'Change-History' }],
    }),
    updatePartnerSkuMapping: builder.mutation<ISKUMappingResponse, IUpdateSKUDataRequestBody>({
      query: (data) => ({
        url: UPDATE_PARTNER_SKU_MAPPING,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: {
        data?: { message: string; meta?: IPaginationMeta };
        error?: { code: number; message: string };
      }) => {
        if (response.data.message === 'Partner SKU Mapping updated') {
          return {
            code: 200,
            message: 'SKU Mapping updated successfully',
            correlationId: response.data?.meta?.correlationId,
          };
        } else {
          return {
            code: response.error.code,
            message: response.data.message,
            correlationId: null,
          };
        }
      },
      invalidatesTags: () => [{ type: 'ApparelManagement', id: 'PendingUpdates' }],
    }),
    updatePartnerSyncStatus: builder.mutation<IPartnerSettingsUpdateResponse, IPartnerSettingsRequest>({
      query: (data) => ({
        url: UPDATE_PARTNER_CONNECT_API_SYNC_STATUS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data: IPartnerSettingsUpdateResponse }) => {
        return response.data;
      },
    }),
    triggerFetchProductsFromPP: builder.mutation<string, ITriggerFetchProductsRequest>({
      query: (data) => ({
        url: TRIGGER_FETCH_PRODUCTS_FROM_PP,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { message: string } }) => {
        return response.data.message;
      },
      invalidatesTags: () => [{ type: 'ApparelManagement', id: 'Fetch products' }],
    }),
    getFetchProductsFromPPStatus: builder.query<ITriggerFetchProductsResponse, ITriggerFetchProductsRequest>({
      query: (data) => ({
        url: GET_FETCH_PRODUCTS_FROM_PP_STATUS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: ITriggerFetchProductsResponse }) => {
        return response.data || { completedAt: '', status: FetchStatus.IN_PROGRESS };
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'Fetch products' }],
    }),
    generateChangeHistoryCSV: builder.mutation<string, IGenerateCSV>({
      query: (data) => ({
        url: GENERATE_CHANGE_HISTORY_CSV,
        method: 'POST',
        body: data,
        responseHandler: (response) => response.text(),
      }),
    }),
    getConfigCatSettingsForApparel: builder.query<IConfigCatResponse, {}>({
      query: (data) => ({
        url: GET_CONFIG_CAT_SETTINGS_APPAREL,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data }) => {
        return resp.data;
      },
    }),
    // API to get mapped categories
    getApparelCategoryMappingList: builder.query<IPartnerSkuMappingResponse, IPartnerSkuMappingRequest>({
      query: (data) => ({
        url: GET_PARTNER_SKU_MAPPING_LIST_DETAILS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: IPartnerSkuMappingResponse }) => {
        return response.data;
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'category-mapping' }],
    }),
     getApparelManufacturerSkuMappingList: builder.query<IManufacturerSkuMappingListResponse, IManufacturerSkuMappingListRequest>({
      query: (data) => ({
        url: GET_MANUFACTURER_SKU_MAPPING_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: IManufacturerSkuMappingListResponse }) => {
        return response.data;
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'manufacturer-sku-mapping' }],
    }),
      getApparelManufacturerSkuUnMappedList: builder.query<IManufacturerSkuUnMappedListResponse, IManufacturerSkuUnMappedListRequest>({
      query: (data) => ({
        url: GET_MANUFACTURER_SKU_UN_MAPPED_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: IManufacturerSkuUnMappedListResponse }) => {
        return response.data;
      },
      providesTags: () => [{ type: 'ApparelManagement', id: 'unmapped-products' }],
    }),
  }),
});

export const {
  useUpdatePartnerSkuMappingMutation,
  useGetConnectApiSyncStatusQuery,
  useUpdatePartnerSyncStatusMutation,
  useLazyGetConnectApiUnSyncedChangesQuery,
  useGetConnectApiUnSyncedChangesQuery,
  useGetFetchProductsFromPPStatusQuery,
  useTriggerFetchProductsFromPPMutation,
  useGetPartnerSkuStatisticsQuery,
  useGetApparelChangeHistoryQuery,
  useGenerateChangeHistoryCSVMutation,
  useLazyGetPendingSkuUpdatesQuery,
  useGetPendingSkuUpdatesQuery,
  useGetConfigCatSettingsForApparelQuery,
  useGetApparelCategoryMappingListQuery,
  useGetApparelManufacturerSkuMappingListQuery,
  useLazyGetApparelCategoryMappingListQuery,
  useLazyGetApparelManufacturerSkuMappingListQuery,
  useLazyGetApparelManufacturerSkuUnMappedListQuery
} = ApparelManagementApi;
