import { createSlice } from '@reduxjs/toolkit';

import { IState } from './Types';
import { ConfigurationApi } from './ConfigurationApi';

const initialState: IState = {
  countryList: [],
  localeList: [],
  timezoneList: [],
  activePartnerCountries: [],
  isLoading: false,
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(ConfigurationApi.endpoints.getConfiguration.matchFulfilled, (state, { payload }) => {
      const { countries, locale } = payload;
      state.countryList = countries;
      state.localeList = locale;
    });
    builder.addMatcher(ConfigurationApi.endpoints.getActivePartnerCountries.matchFulfilled, (state, { payload }) => {
      state.activePartnerCountries = payload;
    });
    builder.addMatcher(ConfigurationApi.endpoints.getTimezonesList.matchFulfilled, (state, { payload }) => {
      state.timezoneList = payload;
    });
  },
});

export default configurationSlice.reducer;
