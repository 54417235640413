import { CSSProperties, FC, useState } from 'react';

import { IProps } from './Types';

const ExpandableText: FC<IProps> = ({ text, showLessEnabled, limit = 100, preserveLineBreak }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const textStyles: CSSProperties = { ...(preserveLineBreak && { whiteSpace: 'pre-line' }) };

  return (
    <div>
      {isExpanded || text.length <= limit ? (
        <>
          <span className="text-break" style={textStyles}>
            {text}{' '}
          </span>
          {isExpanded && showLessEnabled && (
            <span role="button" className="text-primary text-nowrap" onClick={() => setIsExpanded(false)}>
              Show less
            </span>
          )}
        </>
      ) : (
        <>
          <span className="text-break" style={textStyles}>{`${text.substring(0, limit)}... `}</span>
          <span role="button" className="text-primary text-nowrap" onClick={() => setIsExpanded(true)}>
            Show more
          </span>
        </>
      )}
    </div>
  );
};

ExpandableText.defaultProps = {
  showLessEnabled: false,
};

export default ExpandableText;
