import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_USER_NAMES } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { UserInfo } from './Types';

export const UsersAPI = createApi({
  reducerPath: 'UsersAPI',
  tagTypes: [],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getUsersList: builder.query<UserInfo[], string[]>({
      query: (userIds) => ({
        url: GET_USER_NAMES,
        method: 'POST',
        body: { ids: userIds },
      }),
      transformResponse: (resp: { data?: UserInfo[] }) => {
        return resp?.data || [];
      },
    }),
  }),
});

export const { useGetUsersListQuery, useLazyGetUsersListQuery } = UsersAPI;
