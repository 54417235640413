import { createApi } from '@reduxjs/toolkit/query/react';

import { MACHINE_PARK_URL } from 'config/DomainsConfig';
import {
  GET_PRODUCT_PRODUCTION_CAPABILITY_STATE,
  GET_PRODUCTION_CAPABILITY,
  GET_PRODUCTION_CAPABILITY_CATALOG_PRODUCTS,
  GET_PRODUCTION_CAPABILITY_CATALOG_COVERAGE,
  GET_PRODUCTION_CAPABILITY_CATALOG_SUPPORTED_PRINT_HOUSES,
  GET_PRODUCTION_CAPABILITY_PRODUCTS,
} from 'config/MachineParkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import {
  CatalogCoverage,
  CatalogCoverageRequest,
  CatalogCoverageResponse,
  printHouseCapability,
  ProductionCapabilityByProducts,
  ProductionCapabilityCheckData,
  ProductionCapabilityStateComponent,
  ProductListRequest,
  ProductListResponse,
} from './Types';

export const ProductionCapabilityAPI = createApi({
  reducerPath: 'ProductionCapabilityAPI',
  tagTypes: ['ProductionCapability'],
  baseQuery: fetchBaseQueryWrapper(MACHINE_PARK_URL),
  endpoints: (builder) => ({
    getProductCapabilities: builder.query({
      query: (data) => ({
        url: GET_PRODUCTION_CAPABILITY,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ProductionCapability', id: 'LIST' }],
    }),
    getProductsList: builder.query<ProductListResponse, ProductListRequest>({
      query: (data) => ({
        url: GET_PRODUCTION_CAPABILITY_CATALOG_PRODUCTS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: ProductListResponse }) => resp.data,
    }),
    getProductProductionCapabilityState: builder.query<
      ProductionCapabilityCheckData,
      { printHouseUid: string; productUid: string }
    >({
      query: ({ printHouseUid, productUid }) => ({
        url: GET_PRODUCT_PRODUCTION_CAPABILITY_STATE,
        method: 'POST',
        body: { capabilities: [{ printHouseUid, productUid }] },
      }),
      transformResponse: (resp: { data: { capabilities: ProductionCapabilityCheckData[] } }) => {
        return resp?.data?.capabilities?.[0];
      },
    }),
    getProductCapabilityCatalogCoverage: builder.query<CatalogCoverageResponse, CatalogCoverageRequest>({
      query: ({ perPage, pageNumber, ...data }) => ({
        url: GET_PRODUCTION_CAPABILITY_CATALOG_COVERAGE,
        method: 'POST',
        body: {
          ...data,
          pagination: { pageNumber, perPage },
        },
      }),
      transformResponse: (resp: {
        data?: {
          catalog?: CatalogCoverage[];
          pagination?: { totalResult: number };
        };
      }) => {
        return {
          catalog: resp?.data?.catalog || [],
          total: resp?.data?.pagination?.totalResult || 0,
        };
      },
    }),
    getCatalogCapablePrintHouses: builder.query<string[], { catalog: string[] }>({
      query: (data) => ({
        url: GET_PRODUCTION_CAPABILITY_CATALOG_SUPPORTED_PRINT_HOUSES,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { printHouse: string[] } }) => {
        return response.data?.printHouse || [];
      },
    }),
    getProductionCapabilityProducts: builder.query<
      ProductionCapabilityByProducts,
      { productUids: string[]; printHouseUids: string[] }
    >({
      query: ({ productUids, printHouseUids }) => ({
        url: GET_PRODUCTION_CAPABILITY_PRODUCTS,
        method: 'POST',
        body: {
          filters: {
            printHouses: printHouseUids.map((uid) => ({ uid: uid })),
            products: productUids.map((uid) => ({ uid: uid })),
          },
        },
      }),
      transformResponse: (resp: { data?: { products: { uid: string; printHouses?: printHouseCapability[] }[] } }) => {
        return (resp.data?.products || []).reduce(
          (acc, product) => ({
            ...acc,
            [product.uid]: product.printHouses || [],
          }),
          {},
        );
      },
    }),
  }),
});

export const {
  useGetProductCapabilitiesQuery,
  useGetProductsListQuery,
  useGetProductProductionCapabilityStateQuery,
  useGetProductCapabilityCatalogCoverageQuery,
  useLazyGetCatalogCapablePrintHousesQuery,
  useLazyGetProductCapabilityCatalogCoverageQuery,
  useGetProductionCapabilityProductsQuery,
} = ProductionCapabilityAPI;
