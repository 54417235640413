import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  CREATE_BULK_CAPACITY_RULES,
  CREATE_CAPACITY_UNIT,
  CREATE_PARTNER_CAPACITY_RULES,
  DELETE_CAPACITY_UNIT,
  DELETE_PARTNER_CAPACITY_RULES,
  GET_CAPACITY_UNITS,
  GET_FILE_URL,
  GET_PARTNER_CAPACITY_RULES,
  GET_ROUTING_CATALOG_TREE,
  UPDATE_CAPACITY_UNIT,
  UPDATE_PARTNER_CAPACITY_RULES,
} from 'config/GelatoNetworkEndPoints';

import {
  BulkCapacityUpdateResponse,
  CapacityUnit,
  ProductCapacityRule,
  ProductCapacityRuleCreateOrUpdateRequest,
  RoutingProductCatalogItem,
} from './Types';

export const CapacityManagementApi = createApi({
  reducerPath: 'CapacityManagementApi',
  tagTypes: ['capacityUnits'],

  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getRoutingProductCatalog: builder.query<RoutingProductCatalogItem[], void>({
      query: () => ({
        url: GET_ROUTING_CATALOG_TREE,
        method: 'POST',
        body: {},
        keepUnusedDataFor: 86400, // one day
      }),
      transformResponse: (resp: { data: { catalog: RoutingProductCatalogItem[] } }) => {
        return resp?.data?.catalog || [];
      },
    }),
    getCapacityUnitsList: builder.query<CapacityUnit[], void>({
      query: () => ({
        url: GET_CAPACITY_UNITS,
        method: 'GET',
      }),
      transformResponse: (resp: { data: { capacityUnits: CapacityUnit[] } }) => {
        return resp.data?.capacityUnits || [];
      },
      providesTags: ['capacityUnits'],
    }),
    createCapacityUnit: builder.mutation<unknown, CapacityUnit>({
      query: (data) => ({
        url: CREATE_CAPACITY_UNIT,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            CapacityManagementApi.util.updateQueryData('getCapacityUnitsList', undefined, (draft) => {
              draft.push(arg); // add record in cached list
            }),
          );
        } catch {}
      },
    }),
    updateCapacityUnit: builder.mutation<unknown, CapacityUnit & { originalCatalogUid: string }>({
      query: ({ originalCatalogUid, ...data }) => ({
        url: `${UPDATE_CAPACITY_UNIT}/${originalCatalogUid}`,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { originalCatalogUid, ...unit } = arg;
        try {
          await queryFulfilled;
          dispatch(
            CapacityManagementApi.util.updateQueryData('getCapacityUnitsList', undefined, (draft) => {
              const index = draft.findIndex(({ catalogUid }) => catalogUid === originalCatalogUid);
              if (index !== -1) {
                draft[index] = unit; // update record in cached list
              }
            }),
          );
        } catch {}
      },
    }),
    deleteCapacityUnit: builder.mutation<unknown, { catalogUid: string }>({
      query: ({ catalogUid }) => ({
        url: `${DELETE_CAPACITY_UNIT}/${catalogUid}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            CapacityManagementApi.util.updateQueryData('getCapacityUnitsList', undefined, (draft) => {
              return draft.filter(({ catalogUid }) => catalogUid !== arg.catalogUid); // remove record from cached list
            }),
          );
        } catch {}
      },
    }),
    // capacity rules
    getCapacityRules: builder.query<ProductCapacityRule[], { printHouseUid: string }>({
      query: (data) => ({
        url: GET_PARTNER_CAPACITY_RULES,
        method: 'POST',
        body: { includeCapacityUtilization: true, ...data },
      }),
      transformResponse: (resp: { data: { rule: ProductCapacityRule[] } }) => {
        return resp?.data?.rule || [];
      },
    }),
    createCapacityRule: builder.mutation<ProductCapacityRule, ProductCapacityRuleCreateOrUpdateRequest>({
      query: (data) => ({
        url: CREATE_PARTNER_CAPACITY_RULES,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { rule: ProductCapacityRule } }) => {
        return resp?.data?.rule;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: rule } = await queryFulfilled;
          const { printHouseUid } = arg;
          dispatch(
            CapacityManagementApi.util.updateQueryData('getCapacityRules', { printHouseUid }, (draft) => {
              draft.push(rule); // add record in cached list
            }),
          );
        } catch (e) {
          console.log(e);
        }
      },
    }),
    updateCapacityRule: builder.mutation<unknown, ProductCapacityRuleCreateOrUpdateRequest>({
      query: (data) => ({
        url: UPDATE_PARTNER_CAPACITY_RULES,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { printHouseUid, rule } = arg;
        try {
          await queryFulfilled;
          dispatch(
            CapacityManagementApi.util.updateQueryData('getCapacityRules', { printHouseUid }, (draft) => {
              const index = draft.findIndex(({ id }) => id === rule.id);
              if (index !== -1) {
                draft[index] = rule; // update record in cached list
              }
            }),
          );
        } catch (e) {
          console.log(e);
        }
      },
    }),
    deleteCapacityRule: builder.mutation<unknown, { printHouseUid: string; id: number }>({
      query: (data) => ({
        url: DELETE_PARTNER_CAPACITY_RULES,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { printHouseUid, id } = arg;
        try {
          await queryFulfilled;
          dispatch(
            CapacityManagementApi.util.updateQueryData('getCapacityRules', { printHouseUid }, (draft) => {
              return draft.filter((r) => r.id !== id); // remove record from cached list
            }),
          );
        } catch (e) {
          console.log(e);
        }
      },
    }),
    //
    createBulkCapacityRules: builder.mutation<BulkCapacityUpdateResponse, { csvFile: File }>({
      query: ({ csvFile }) => {
        const formData = new FormData();
        formData.append('capacity-conf-bulk', csvFile); //  'meta.arg.originalArgs.csvFile'  added in store ignore path to avoid serialization error
        return {
          url: CREATE_BULK_CAPACITY_RULES,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getFileUrl: builder.query<string, { storageKey: string }>({
      query: (data) => ({
        url: GET_FILE_URL,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { url: string } }) => {
        return resp?.data?.url;
      },
    }),
  }),
});

export const {
  useGetRoutingProductCatalogQuery,
  useGetCapacityUnitsListQuery,
  useCreateCapacityUnitMutation,
  useUpdateCapacityUnitMutation,
  useDeleteCapacityUnitMutation,
  useGetCapacityRulesQuery,
  useCreateCapacityRuleMutation,
  useUpdateCapacityRuleMutation,
  useDeleteCapacityRuleMutation,
  useCreateBulkCapacityRulesMutation,
  useLazyGetFileUrlQuery,
} = CapacityManagementApi;
