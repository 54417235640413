import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_PRINT_HOUSE_FTP_STATUS } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

export const FtpStatusApi = createApi({
  reducerPath: 'FtpStatusApi',
  tagTypes: ['ftpStatus'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getFtpStatus: builder.query<{ status: 'failed' | 'ok'; dateTime: string; error: string }, string>({
      query: (phUid) => ({
        url: GET_PRINT_HOUSE_FTP_STATUS,
        method: 'POST',
        body: { printHouseUid: [phUid] },
      }),
      transformResponse: (resp: {
        data: {
          printHouses: {
            printHouseUid: string;
            ftpStatus: { status: 'failed' | 'ok'; dateTime: string; error: string }[];
          }[];
        };
      }) => {
        return resp?.data?.printHouses[0]?.ftpStatus[0];
      },
      providesTags: ['ftpStatus'],
    }),
  }),
});

export const { useGetFtpStatusQuery } = FtpStatusApi;
