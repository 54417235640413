import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { ORDER_ISSUES } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { IssueTicketInfo } from './Types';

export const OrderIssuesApi = createApi({
  reducerPath: 'ZendeskIssuesApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getOrderIssues: builder.query<IssueTicketInfo[], string>({
      query: (orderId) => ({
        url: ORDER_ISSUES,
        method: 'POST',
        body: { orderId },
      }),
      transformResponse: (resp: { data?: { issues: IssueTicketInfo[] } }) => {
        return resp.data?.issues || [];
      },
    }),
  }),
});

export const { useGetOrderIssuesQuery } = OrderIssuesApi;
