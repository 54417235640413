import { createSlice } from '@reduxjs/toolkit';

import { TranslationsAPI } from './TranslationsAPI';

const initialState = {
  translations: {} as Record<string, string>,
};

const TranslationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(TranslationsAPI.endpoints.getTranslations.matchFulfilled, (state, { payload }) => {
      state.translations = payload || initialState.translations;
    });
  },
});

export default TranslationsSlice.reducer;
