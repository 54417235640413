export const SECTIONS = {
  FILE_UPLOAD_SCHEDULER: 'file-upload-scheduler',
  PARTNER_DETAILS: 'partner-details',
  APPAREL_OOS: 'apparel-oos',
  FTP_CONNECT_CONFIG: 'ftp/connect-config',
  PRODUCTION_DAYS: 'production-days',
  PRODUCTION_TIME: 'production-time',
  DELIVERY_TIME: 'delivery-time',
  PRICING_MODELS_MANAGEMENT: 'pricing-model-management',
  WORKFLOW_CONFIGURATION: 'workflow-configuration',
  APPAREL_MANAGEMENT: 'apparel-management',
  CAPACITY_UNITS: 'capacity-units',
  CAPACITY_MANAGEMENT: 'capacity-management',
  O2D_CONFIG: 'o2d-config',
};

export const SUBJECT_TYPES = {
  PRINT_HOUSE_UID: 'printHouseUid',
  PRODUCT_UID: 'productUid',
};
