import { createApi } from '@reduxjs/toolkit/query/react';

import { ROUTING_URL } from 'config/DomainsConfig';
import { GET_SUPPORTED_CLUSTERS_COUNTRIES, GET_SUPPORTED_CLUSTERS_LOCATIONS } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import {
  IProductionCountriesRequest,
  IProductionCountryObject,
  IProductionLocationObject,
  IProductionLocationsRequest,
} from './Types';

export const ClusterApi = createApi({
  reducerPath: 'ClusterApi',
  tagTypes: ['ApparelCatalog'],
  baseQuery: fetchBaseQueryWrapper(ROUTING_URL),
  endpoints: (builder) => ({
    getProductionClusters: builder.query<string[], IProductionCountriesRequest>({
      query: (data) => ({
        url: GET_SUPPORTED_CLUSTERS_LOCATIONS,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ApparelCatalog', id: 'clusters' }],
      transformResponse: (response: { data?: { countries: IProductionCountryObject[] } }) => {
        return response.data?.countries
          .map(({ productionLocationList }) =>
            productionLocationList.map(({ productionLocationUid }) => productionLocationUid),
          )
          .flat();
      },
    }),
    getProductionClusterSupportedPrintHouses: builder.query<IProductionLocationObject[], IProductionLocationsRequest>({
      query: (data) => ({
        url: GET_SUPPORTED_CLUSTERS_COUNTRIES,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ApparelCatalog', id: 'ProductsStatus' }],
      transformResponse: (response: { data?: { productionLocations: IProductionLocationObject[] } }) => {
        return response.data?.productionLocations;
      },
    }),
  }),
});

export const { useGetProductionClustersQuery, useGetProductionClusterSupportedPrintHousesQuery } = ClusterApi;
