import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_PRODUCT_INFO_LIST } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ProductInfo } from './Types';

export const ProductInfoListApi = createApi({
  reducerPath: 'ProductInfoListApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getProductInfoList: builder.query<ProductInfo[], { productUID: string[] }>({
      query: (data) => ({
        url: GET_PRODUCT_INFO_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { product: Array<ProductInfo> } }) => response?.data?.product || [],
    }),
  }),
});

export const { useGetProductInfoListQuery } = ProductInfoListApi;
