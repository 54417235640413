import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  GET_COUNTRIES_LIST,
  GET_PRINT_PLATES,
  GET_PRINT_PLATE_BUILD_ENGINES,
  GET_PRINT_PLATE_ERROR_GROUPS,
  GET_PRINT_PLATE_HISTORY,
  GET_PRINT_PLATE_IMPOSITION_DETAILS,
  GET_PRINT_PLATE_REQUEST_PAYLOAD,
  GET_PRINT_PLATE_STATUSES,
  PRINT_JOBS_DETAILS,
  PRINT_PLATES_UPLOAD,
  SET_PRINT_PLATES_STATUS,
  PRINT_PLATES_FILE_APPLY_FIXUP,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { showToast } from 'store/Toast/Toast';

import {
  PrintPlatesFilter,
  PrintPlateInfo,
  PrintPlateEventInfo,
  PrintPlateStatusMutationData,
  PrintPlateStatusMutationResult,
  PrintPlateUploadMutationData,
  PrintPlatePayloadArtifact,
  PrintPlateStatus,
  PrintPlateBuildEngine,
  PrintJobInfo,
  PrintPlateFileApplyFixupMutationData,
  PrintPlatesListingData,
  PlateStatusStatistic,
  PrintHouseStatistic,
} from './Types';

export const PrintPlatesApi = createApi({
  reducerPath: 'PrintPlatesApi',
  tagTypes: ['Plate', 'PlateHistory', 'PlateArtifacts'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getPrintPlates: builder.query<PrintPlatesListingData, PrintPlatesFilter>({
      query: ({ page, pageSize, ...filters }) => ({
        url: GET_PRINT_PLATES,
        method: 'POST',
        body: {
          withStatisticsByStatus: true,
          ...filters,
          pagination: { pageNumber: page, perPage: pageSize },
        },
      }),
      transformResponse: (resp: {
        data?: {
          printPlate?: PrintPlateInfo[];
          pagination?: { totalResult: number };
          statusStatistic?: PlateStatusStatistic[];
          printHouseStatistic?: PrintHouseStatistic[];
          warningMessage?: string;
        };
      }) => {
        return {
          printPlates: resp?.data?.printPlate || [],
          statusStatistics: resp?.data?.statusStatistic || [],
          printHouseStatistic: resp?.data?.printHouseStatistic || [],
          total: resp?.data?.pagination?.totalResult || 0,
          warningMessage: resp?.data?.warningMessage || '',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (arg.withStatisticsByPrintHouse && data?.warningMessage) {
            dispatch(showToast({ type: 'success', content: data.warningMessage }));
          }
        } catch {}
      },
      providesTags: ['Plate'],
    }),
    getPrintPlateHistory: builder.query<PrintPlateEventInfo[], number>({
      query: (printPlateId) => ({
        url: GET_PRINT_PLATE_HISTORY,
        method: 'POST',
        body: { printPlateId },
      }),
      transformResponse: (resp: { data?: { eventHistory: PrintPlateEventInfo[] } }) => {
        return resp.data?.eventHistory || [];
      },
      providesTags: ['PlateHistory'],
    }),
    changePrintPlatesStatus: builder.mutation<PrintPlateStatusMutationResult, PrintPlateStatusMutationData>({
      query: ({ printPlateIds, statusUid, reason }) => ({
        url: SET_PRINT_PLATES_STATUS,
        method: 'POST',
        body: {
          printPlate: printPlateIds.map((id) => ({ printPlateId: id, statusUid, comment: reason })),
        },
      }),
      transformResponse: (resp: { data?: PrintPlateStatusMutationResult }) => {
        return resp.data;
      },
      invalidatesTags: (result) => (result ? ['Plate', 'PlateHistory'] : []), // only invalidate on success
    }),
    triggerPrintPlateFileApplyFixup: builder.mutation<{ message: string }, PrintPlateFileApplyFixupMutationData>({
      query: ({ printPlateId, ...data }) => ({
        url: PRINT_PLATES_FILE_APPLY_FIXUP,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data?: { message: string } }) => {
        return resp.data;
      },
      invalidatesTags: (result, _, { printPlateId }) => (result ? [{ type: 'PlateArtifacts', id: printPlateId }] : []),
    }),
    triggerPrintPlatesUpload: builder.mutation<{ message: string }, PrintPlateUploadMutationData>({
      query: ({ printPlateIds, reason, printHouseUid }) => ({
        url: PRINT_PLATES_UPLOAD,
        method: 'POST',
        body: {
          printPlateId: printPlateIds,
          printHouseUid,
          reason,
        },
      }),
      transformResponse: (resp: { data?: { message: string } }) => {
        return resp.data;
      },
      invalidatesTags: ['Plate', 'PlateHistory'],
    }),
    getPlateImpositionDetails: builder.query<object, number>({
      query: (printPlateId) => ({
        url: GET_PRINT_PLATE_IMPOSITION_DETAILS,
        method: 'POST',
        body: { printPlateId },
      }),
      transformResponse: (resp: { data?: object }) => {
        return resp.data;
      },
    }),
    getPlateArtifacts: builder.query<PrintPlatePayloadArtifact[], number>({
      query: (printPlateId) => ({
        url: GET_PRINT_PLATE_REQUEST_PAYLOAD,
        method: 'POST',
        body: { entity: 'print-plate', entityId: `${printPlateId}` },
      }),
      transformResponse: (resp: { data?: { artifacts: PrintPlatePayloadArtifact[] } }) => {
        return resp.data?.artifacts || [];
      },
      providesTags: (_, __, plateID) => [{ type: 'PlateArtifacts', id: plateID }],
    }),
    getPrintPlateStatuses: builder.query<PrintPlateStatus[], unknown>({
      query: () => ({
        url: GET_PRINT_PLATE_STATUSES,
        method: 'GET',
      }),
      transformResponse: (resp: { data?: { status: PrintPlateStatus[] } }) => {
        return resp.data?.status || [];
      },
      keepUnusedDataFor: 86400, // one day
    }),
    getPrintPlateBuildEngines: builder.query<PrintPlateBuildEngine[], unknown>({
      query: () => ({
        url: GET_PRINT_PLATE_BUILD_ENGINES,
        method: 'GET',
      }),
      transformResponse: (resp: { data?: { buildEngine: PrintPlateBuildEngine[] } }) => {
        return resp.data?.buildEngine || [];
      },
      keepUnusedDataFor: 86400, // one day
    }),
    getPrintPlateErrorGroups: builder.query<
      PrintPlateInfo['errorClassification'][],
      { dateFrom: string; dateTo: string }
    >({
      query: (data) => ({
        url: GET_PRINT_PLATE_ERROR_GROUPS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data?: { classification: PrintPlateInfo['errorClassification'][] } }) => {
        return resp.data?.classification || [];
      },
    }),
    getCountriesList: builder.query<string[], unknown>({
      query: () => ({
        url: GET_COUNTRIES_LIST,
        method: 'POST',
        body: {},
      }),
      transformResponse: (resp: { data?: { isoCode: string[] } }) => {
        return resp.data?.isoCode || [];
      },
      keepUnusedDataFor: 86400, // one day
    }),
    getPrintJobsDetails: builder.query<PrintJobInfo[], number[]>({
      query: (printJobIds) => ({
        url: PRINT_JOBS_DETAILS,
        method: 'POST',
        body: { printJobId: printJobIds },
      }),
      transformResponse: (resp: { data?: { printJobDetails: PrintJobInfo[] } }) => {
        return resp.data?.printJobDetails || [];
      },
    }),
  }),
});

export const {
  useGetPrintPlatesQuery,
  useGetPrintPlateHistoryQuery,
  useChangePrintPlatesStatusMutation,
  useTriggerPrintPlatesUploadMutation,
  useTriggerPrintPlateFileApplyFixupMutation,
  useGetPlateImpositionDetailsQuery,
  useGetPlateArtifactsQuery,
  useGetPrintPlateStatusesQuery,
  useGetPrintPlateBuildEnginesQuery,
  useGetPrintPlateErrorGroupsQuery,
  useGetCountriesListQuery,
  useGetPrintJobsDetailsQuery,
} = PrintPlatesApi;
