import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  GET_CONFIG_CAT_SETTINGS_APPAREL,
  GET_COUNTRIES_HOLIDAYS,
  GET_WORKING_NON_WORKING_DAYS,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { CountryHolidays, CountryHolidaysRequestData } from './Types';

export const DashboardAPI = createApi({
  reducerPath: 'DashboardAPI',
  tagTypes: ['DashboardAPI'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getNonWorkingDays: builder.query({
      query: (data) => ({
        url: GET_WORKING_NON_WORKING_DAYS,
        method: 'POST',
        body: data,
      }),
    }),
    getPendingRequests: builder.query({
      query: (data) => ({
        url: GET_WORKING_NON_WORKING_DAYS,
        method: 'POST',
        body: data,
      }),
    }),
    getCountriesHolidays: builder.query<CountryHolidays[], CountryHolidaysRequestData>({
      query: (data) => ({
        url: GET_COUNTRIES_HOLIDAYS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { countryHolidays: CountryHolidays[] } }) => {
        return resp.data.countryHolidays || [];
      },
    }),
  }),
});

export const { useGetNonWorkingDaysQuery, useGetPendingRequestsQuery, useGetCountriesHolidaysQuery } = DashboardAPI;
