import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  FILE_UPLOAD_SCHEDULER_LIST_V2,
  FILE_UPLOAD_SCHEDULER_TOGGLE_V2,
  FILE_UPLOAD_SCHEDULER_BATCH_UPDATE_V2,
  FILE_UPLOAD_SCHEDULER_DELETE_V2,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { GELATO_CLIENT_UID } from 'config/ClientId';

import {
  DaySpecificFileUploadScheduleRuleV2,
  FileUploadScheduleRuleV2,
  FileUploadSchedulerListDataV2,
  UploadScheduleBatchV2,
  UploadScheduleDeleteRequestV2,
  UploadScheduleToggleRequest,
} from './Types';

export const FileUploadSchedulerV2Api = createApi({
  reducerPath: 'FileUploadSchedulerV2Api',
  tagTypes: ['FileUploadSchedulerV2'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    fileUploadSchedulerList: builder.query<FileUploadSchedulerListDataV2, { printHouseUid: string }>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_LIST_V2,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: {
        data: {
          printHouseUid: string;
          scheduleRule: (FileUploadScheduleRuleV2 | DaySpecificFileUploadScheduleRuleV2)[];
        };
      }) => {
        const { scheduleRule } = resp.data;
        const gelatoRules = scheduleRule.filter(
          ({ tenantCustomerUid }) => !tenantCustomerUid || tenantCustomerUid === GELATO_CLIENT_UID,
        );
        const nonGelatoRules = scheduleRule.filter(
          ({ tenantCustomerUid }) => tenantCustomerUid && tenantCustomerUid !== GELATO_CLIENT_UID,
        );
        return {
          gelatoRules: {
            scheduleRule: gelatoRules.filter(
              ({ date }: DaySpecificFileUploadScheduleRuleV2) => !date,
            ) as FileUploadScheduleRuleV2[],
            daySpecificRule: gelatoRules.filter(
              ({ date }: DaySpecificFileUploadScheduleRuleV2) => date,
            ) as DaySpecificFileUploadScheduleRuleV2[],
          },
          nonGelatoRules: {
            scheduleRule: nonGelatoRules as FileUploadScheduleRuleV2[],
          },
        };
      },
      providesTags: () => [{ type: 'FileUploadSchedulerV2' }],
    }),
    fileUploadSchedulerToggle: builder.mutation<UploadScheduleBatchV2, UploadScheduleToggleRequest>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_TOGGLE_V2,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'FileUploadSchedulerV2' }] : []), // only invalidate on success
      transformResponse: (resp: { data: UploadScheduleBatchV2 }) => resp?.data,
    }),
    updateFileUploadScheduler: builder.mutation<UploadScheduleBatchV2, UploadScheduleBatchV2>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_BATCH_UPDATE_V2,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'FileUploadSchedulerV2' }] : []), // only invalidate on success
      transformResponse: (resp: { data: UploadScheduleBatchV2 }) => resp?.data,
    }),
    deleteFileUploadScheduler: builder.mutation<unknown, UploadScheduleDeleteRequestV2>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_DELETE_V2,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'FileUploadSchedulerV2' }],
    }),
  }),
});

export const {
  useFileUploadSchedulerListQuery,
  useFileUploadSchedulerToggleMutation,
  useUpdateFileUploadSchedulerMutation,
  useDeleteFileUploadSchedulerMutation,
} = FileUploadSchedulerV2Api;
