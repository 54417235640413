import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GELATO_CREATE_COMPANY_LIST, ORDER_LIST } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { setCurrentRequestId } from './OrderSlice';
import { CompanyInfo, IOrder, MPJSearchRequest } from './Types';

export const OrderApi = createApi({
  reducerPath: 'OrderApi',
  tagTypes: ['Order'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getOrders: builder.query<{ data: { orders: IOrder[] } }, MPJSearchRequest & { fakeProp?: string }>({
      query: ({ fakeProp, ...data }) => ({
        url: ORDER_LIST,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'Order', id: 'LIST' }],
      onQueryStarted(data, { dispatch, requestId }) {
        dispatch(setCurrentRequestId(requestId));
      },
    }),
    getGelatoCreateCompanyList: builder.query<CompanyInfo[], { companyName: string; limit: number }>({
      query: (data) => ({
        url: GELATO_CREATE_COMPANY_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data: { gelatoCreateCompany: CompanyInfo[] } }) =>
        response.data?.gelatoCreateCompany || [],
    }),
  }),
});

export const { useGetOrdersQuery, useGetGelatoCreateCompanyListQuery } = OrderApi;
