import { createApi } from '@reduxjs/toolkit/query/react';

import { CONNECT_API_URL } from 'config/DomainsConfig';
import {
  GET_FTP_API_CONFIG,
  RESET_FTP_API_CONFIG,
  SETUP_FTP_API_CONFIG,
  TEST_FTP_API_CONFIG,
} from 'config/ConnectApiEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import {
  APIConfig,
  APIIntegration,
  FTPandAPIConfigResponse,
  FTPConfig,
  FTPIntegration,
  IState,
  ResetFTPandAPIConfigRequest,
} from './Types';
import { FtpStatusApi } from './FtpStatusApi';

export const FtpandApiConfigApi = createApi({
  reducerPath: 'FtpandApiConfigApi',
  tagTypes: ['FtpApi', 'AuditLogs'],
  baseQuery: fetchBaseQueryWrapper(CONNECT_API_URL),
  endpoints: (builder) => ({
    getFtpandApiConfig: builder.query<IState, string>({
      query: (data) => ({
        url: GET_FTP_API_CONFIG + '?uid=' + data,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'FtpApi' }],
      transformResponse: (resp: FTPandAPIConfigResponse) => {
        let ftp: FTPConfig;
        let api: APIConfig;

        const ftpIntegration = resp.integrations?.find((i) => i.integration === 'ftp') as FTPIntegration;
        if (ftpIntegration) {
          ftp = {
            ...ftpIntegration.credentials,
            config: ftpIntegration.config ? JSON.stringify(ftpIntegration.config, null, 4) : '',
          };
        }
        const apiIntegration = resp.integrations?.find((i) => i.integration === 'api') as APIIntegration;
        if (apiIntegration) {
          api = apiIntegration.credentials;
        }
        return { ftp, api };
      },
    }),
    updateFtpandApiConfig: builder.mutation({
      query: (data) => ({
        url: SETUP_FTP_API_CONFIG,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'FtpApi' }, { type: 'AuditLogs', id: 'LIST' }],
    }),
    testFtpandApiConfig: builder.mutation({
      query: (data) => ({
        url: TEST_FTP_API_CONFIG,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {}
        // refetch in any case
        dispatch(FtpStatusApi.util.invalidateTags(['ftpStatus']));
      },
    }),
    resetFTPorApiConfig: builder.mutation<unknown, ResetFTPandAPIConfigRequest>({
      query: (data) => ({
        url: RESET_FTP_API_CONFIG,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [{ type: 'FtpApi' }, { type: 'AuditLogs', id: 'LIST' }],
    }),
    toggleLongtailValue: builder.mutation<unknown, { phUid: string; useLongTail: boolean }>({
      query: ({ phUid, useLongTail }) => ({
        url: `/v1/account/${phUid}/use-long-tail`,
        method: 'PUT',
        body: { useLongTail },
      }),
      invalidatesTags: [{ type: 'FtpApi' }, { type: 'AuditLogs', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetFtpandApiConfigQuery,
  useUpdateFtpandApiConfigMutation,
  useTestFtpandApiConfigMutation,
  useResetFTPorApiConfigMutation,
  useToggleLongtailValueMutation,
} = FtpandApiConfigApi;
