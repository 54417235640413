import { FC, ReactNode } from 'react';

import { useAppSelector } from 'store/hooks';

import Toast from 'components/Toast';
import useMenuItems from 'hooks/useMenuItems';

import Header from './Header';
import Menu from './Menu';
import RefreshTokenWrapper from './RefreshTokenWrapper';
import { IMenuItem } from './Types';
import { usePartnerViewInfo } from './PartnerViewInfoProvider';

interface IProps {
  children: ReactNode;
}

const Layout: FC<IProps> = ({ children }) => {
  const { isInPartnerView } = usePartnerViewInfo();
  const authenticated = useAppSelector((state) => state.auth.authenticated);
  const menuItems: IMenuItem[] = useMenuItems();

  return (
    <>
      {authenticated && (
        <>
          {!isInPartnerView && (
            <>
              {/* only render these if not in partner app */}
              <Header />
              <Menu items={menuItems} />
            </>
          )}
          <Toast />
          <RefreshTokenWrapper />
        </>
      )}
      <div className={`${isInPartnerView ? 'px-4' : 'content-wrapper p-4'}`}>{children}</div>
    </>
  );
};

export default Layout;
