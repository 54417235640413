import * as rudder from 'rudder-sdk-js';

import { RS_WRITE_KEY, RS_DATA_PLANE } from 'config/RudderStackConfig';
import { IS_LIVE, IS_STAGING } from 'config/Environment';

// call only once on app init
let isCalled = false;
export function rudderInitialize() {
  if (isCalled || (!IS_LIVE && !IS_STAGING)) return;

  rudder.ready(() => {
    // console.log('we are all set!!!');
  });

  try {
    rudder.load(RS_WRITE_KEY, RS_DATA_PLANE, {
      logLevel: 'DEBUG',
      integrations: { All: true },
    });
  } catch (error) {
    console.error(error);
  }
  isCalled = true;
}

export function eventIdentify({ userId, userName }: { userId: string; userName: string }) {
  if (!IS_LIVE && !IS_STAGING) return;
  rudder.identify(userId, { userName });
}

export function eventPage() {
  if (!IS_LIVE && !IS_STAGING) return;
  rudder.page();
}

export const eventTrack = (event: string, properties?: rudder.apiObject) => {
  if (!IS_LIVE && !IS_STAGING) return;
  rudder.track(event, properties);
};
