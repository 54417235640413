export const GET_PRICING_MODELS = '/v2/pricing-model';
export const GET_AGREEMENT_VERSIONS = '/v2/amt/admin/agreement-version/list';
export const GET_LATEST_AGREEMENT_VERSION = '/v2/amt/admin/agreement-version/current';
export const GET_CONFIGURATION_BY_AGREEMENT_VERSIONS = '/v2/pricing-model/version'; // /{version}
export const ACTIVATE_PRICING_MODEL = '/v2/pricing-model/activate';
export const ACTIVATE_PRESET_PRICING_MODELS = '/v2/pricing-model/preset/activate';
export const DEACTIVATE_PRICING_MODEL = '/v2/pricing-model/deactivate';
export const DISCARD_PRICING_MODEL = '/v2/pricing-model/discard-draft';
export const UPDATE_DRAFT_PRICING = '/v2/amt/admin/value/draft-change-bulk';
export const GET_DRAFT_PRICING_LIST = '/v3/amt/admin/value/list';
export const GET_AGREEMENT_PRICING_CATEGORY_LIST = '/v1/agreement-category/detailed-subtree';

// pricing API's for apparel management

export const GET_APPAREL_PRICES_LIST = '/v3/amt/admin/value/list';
export const REACT_ADMIN_TOOL = '/admin_react/agreement_management_tool/partner/list';
export const GET_PRICE_MODELS_WITH_DATE = '/v2/pricing-model/party/{partyUid}/date/{date}'
