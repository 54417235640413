import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IState, ProductionDay, ProductionDayInfo } from './Types';
import { ProductionDaysApi } from './ProductionDaysApi';

const initialState: IState = {
  productionDayRequest: [],
};

const ProductionDaysSlice = createSlice({
  name: 'ProductionDays',
  initialState,
  reducers: {
    // TODO:  fix these actions or remove them
    rejectApprovedProductionDay: (state, action) => {
      // state.approvedList = [...state.approvedList.filter(({ uuid }) => action.payload.uuid !== uuid)];
    },
    editApprovedProductionDay: (state, action) => {
      // const editedDayIndex = state.approvedList.findIndex(({ uuid }) => uuid === action.payload.uuid);
      // const approvedDaysLocal = [...state.approvedList];
      // approvedDaysLocal[editedDayIndex] = action.payload;
      // state.approvedList = approvedDaysLocal;
    },
    approveProductionDay: (state, action) => {
      // const approvedRequestIndex = state.productionDayRequest.findIndex(({ uuid }) => uuid === action.payload.uuid);
      // const requestsLocal = [...state.productionDayRequest];
      // requestsLocal[approvedRequestIndex] = action.payload;
      // state.productionDayRequest = requestsLocal;
    },
    addNewProductionDay: (state, action) => {
      // state.approvedList = [...state.approvedList, ...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ProductionDaysApi.endpoints.getProductionDays.matchFulfilled, (state, { payload }) => {
      state.productionDayRequest = payload;
    });
  },
});

export const { rejectApprovedProductionDay, editApprovedProductionDay, approveProductionDay, addNewProductionDay } =
  ProductionDaysSlice.actions;

export default ProductionDaysSlice.reducer;
