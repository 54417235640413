import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import ErrorBoundary from 'core/ErrorBoundary';
import { PartnerViewInfoProvider } from 'core/PartnerViewInfoProvider';

import App from './App';
import { store } from './store/store';
import './styles/scss/index.scss';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PartnerViewInfoProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </PartnerViewInfoProvider>
  </Provider>,
);

serviceWorker.unregister();
