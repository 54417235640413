import { createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION_FILTER_VALUE } from 'containers/NewsFeed/constants';
import { MESSAGE_TYPE } from 'store/NewsFeed/constants';
import { NewsFeedApi } from 'store/NewsFeed/NewsFeedApi';
import type { NewsFeedState } from 'store/NewsFeed/Types';

const initialState: NewsFeedState = {
  banners: [],
  messages: [],
  messagePagination: {
    offset: 0,
    limit: 25,
    total: 0,
  },
  bannerPagination: {
    offset: 0,
    limit: 25,
    total: 0,
  },
};

const NewsFeedSlice = createSlice({
  name: 'NewsFeedSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(NewsFeedApi.endpoints.getMessages.matchFulfilled, (state, { payload }) => {
      state.messages = payload.notifications.map((item) => {
        const { isAlert, isFeatured } = item;

        let type: MESSAGE_TYPE = MESSAGE_TYPE.info;

        if (isAlert) {
          type = MESSAGE_TYPE.alert;
        } else if (isFeatured) {
          type = MESSAGE_TYPE.feature;
        }

        return {
          image: null,
          printHouses: ALL_OPTION_FILTER_VALUE,
          roles: ALL_OPTION_FILTER_VALUE,
          author: '',
          isSilent: false,
          ...item,
          type,
        };
      });

      state.messagePagination = {
        offset: payload.offset,
        limit: payload.limit,
        total: payload.total,
      };
    });

    builder.addMatcher(NewsFeedApi.endpoints.getBanners.matchFulfilled, (state, { payload }) => {
      state.banners = payload.banners || [];
      state.bannerPagination = {
        offset: payload.offset,
        limit: payload.limit,
        total: payload.total,
      };
    });
  },
});

export default NewsFeedSlice.reducer;
