import { FC } from 'react';
import { Redirect } from 'react-router';

import { usePermissions } from 'hooks/useCheckPermissions';

import { menuItems } from './constants/menuItems';

const RoleBasedParentToChildRedirectRoute: FC<{ path: string; to: string }> = ({ path, to }) => {
  const { hasAnyRole } = usePermissions();

  let redirectTo = to;

  const pathMenuItem = menuItems.find(({ url }) => url === path);
  if (pathMenuItem) {
    const accessibleChildren = pathMenuItem.items.filter(({ scopes }) => hasAnyRole(scopes));
    if (!accessibleChildren.find(({ url }) => url === to) && accessibleChildren.length) {
      // if redirect url is not accessible and we have other accessible route, then use that route for redirection
      redirectTo = accessibleChildren[0]?.url;
    }
  }

  return <Redirect path={path} to={redirectTo} />;
};

export default RoleBasedParentToChildRedirectRoute;
