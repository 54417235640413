import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  GET_PHMT_PRINT_HOUSE,
  UPDATE_PHMT_PRINT_HOUSE,
  MAP_PHMT_PRINT_HOUSE,
  GET_PHMT_PRINT_HOUSES,
  CREAT_PHMT_PRINT_HOUSE,
  V1_GET_CAPABLE_PRINT_HOUSES,
  V2_GET_CAPABLE_PRINT_HOUSES,
  UPDATE_PRINT_HOUSE_PARTNER_MANAGER_SUFFIX,
  UPDATE_PRINT_HOUSE_PARTNER_MANAGER_PREFIX,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import {
  PackageCapablePrintPartnersV1ResponseData,
  PrintHouse,
  PackageCapablePrintPartnersV2ResponseData,
} from './Types';

export const PrintHouseApi = createApi({
  reducerPath: 'PrintHouseApi',
  tagTypes: ['PrintHouse', 'v2CapablePrintHouses'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getPrintHouses: builder.query({
      query: (data) => ({
        url: GET_PHMT_PRINT_HOUSES,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'PrintHouse', id: 'LIST' }],
    }),
    getPrintHouse: builder.query<PrintHouse, { printHouseUid: string }>({
      query: (data) => ({
        url: GET_PHMT_PRINT_HOUSE,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'PrintHouse', id: 'GET' }],
      transformResponse: (resp: { data: { printHouse: PrintHouse } }) => {
        return resp.data?.printHouse;
      },
    }),
    createPrintHouse: builder.mutation<PrintHouse, unknown>({
      query: (data) => ({
        url: CREAT_PHMT_PRINT_HOUSE,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { printHouse: PrintHouse } }) => {
        return resp.data?.printHouse;
      },
      invalidatesTags: [{ type: 'PrintHouse', id: 'LIST' }],
    }),
    updatePrintHouse: builder.mutation({
      query: (data) => ({
        url: UPDATE_PHMT_PRINT_HOUSE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'PrintHouse', id: 'GET' },
        { type: 'PrintHouse', id: 'LIST' },
      ],
    }),
    mapPrintHouse: builder.mutation<PrintHouse, { printHouseUid: string }>({
      query: (data) => ({
        url: MAP_PHMT_PRINT_HOUSE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'PrintHouse', id: 'GET' }],
    }),
    v1CapablePrintHouses: builder.query<PackageCapablePrintPartnersV1ResponseData, { packageId: string[] }>({
      query: (data) => ({
        url: V1_GET_CAPABLE_PRINT_HOUSES,
        method: 'POST',
        body: data,
      }),
      providesTags: [{ type: 'PrintHouse', id: 'LIST' }],
    }),
    v2CapablePrintHouses: builder.query<PackageCapablePrintPartnersV2ResponseData, { packageId: string[] }>({
      query: (data) => ({
        url: V2_GET_CAPABLE_PRINT_HOUSES,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: PackageCapablePrintPartnersV2ResponseData }) => {
        return response.data;
      },
      providesTags: ['v2CapablePrintHouses'],
    }),
    updatePrintHousePartnerManager: builder.mutation<unknown, { printHouseUid: string; managerUUid: string }>({
      query: ({ printHouseUid, managerUUid }) => ({
        url: `${UPDATE_PRINT_HOUSE_PARTNER_MANAGER_PREFIX}/${printHouseUid}/${UPDATE_PRINT_HOUSE_PARTNER_MANAGER_SUFFIX}`,
        method: 'POST',
        body: { uuid: managerUUid },
      }),
      invalidatesTags: [
        // TODO: optimistic update would bebetter experince instead of invlaidation ?
        { type: 'PrintHouse', id: 'GET' },
        { type: 'PrintHouse', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetPrintHousesQuery,
  useGetPrintHouseQuery,
  useCreatePrintHouseMutation,
  useUpdatePrintHouseMutation,
  useMapPrintHouseMutation,
  useV1CapablePrintHousesQuery,
  useV2CapablePrintHousesQuery,
  useUpdatePrintHousePartnerManagerMutation,
} = PrintHouseApi;
