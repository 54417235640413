import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryGNXWrapper } from 'core/Helpers/baseQuery';
import { applyParamsToUrl } from 'core/Helpers/url';
import { REACT_APP_GNX_URL } from 'config/DomainsConfig';

import {
  MACHINE_LIST,
  MACHINE_LOGS,
  MACHINE_LOG_FILE,
  MACHINE_REQUEST_LOG,
  MACHINE_SUPPORTED_VERSION,
  MACHINE_SUPPORTED_VERSION_UPDATE,
} from 'config/GNXEndPoints';

import { GNXResponse, MachineLogsResponse, MachineSupportedVersionResponse, MachinesResponse } from './Types';

export const MachineApi = createApi({
  reducerPath: 'MachineApi',
  baseQuery: fetchBaseQueryGNXWrapper(REACT_APP_GNX_URL),
  endpoints: (builder) => ({
    getMachines: builder.query<MachinesResponse, void>({
      query: () => ({
        url: MACHINE_LIST,
        method: 'GET',
      }),
      transformResponse: ({ data }: GNXResponse<MachinesResponse>) => data,
    }),
    getMachineLogs: builder.query<MachineLogsResponse, string>({
      query: (id: string) => ({
        url: applyParamsToUrl(MACHINE_LOGS, { id }),
        method: 'GET',
      }),
      transformResponse: ({ data }: GNXResponse<MachineLogsResponse>) => data,
    }),
    getMachineLogFile: builder.query<string, { machineId: string; logId: string; fileId: string }>({
      query: ({ machineId, logId, fileId }) => ({
        url: applyParamsToUrl(MACHINE_LOG_FILE, { machineId, logId, fileId }),
        method: 'GET',
      }),
      transformResponse: ({ data }: GNXResponse<{ s3: string }>) => data.s3,
    }),
    requestLogs: builder.mutation<void, { machineId: string }>({
      query: ({ machineId }) => ({
        url: applyParamsToUrl(MACHINE_REQUEST_LOG, { machineId }),
        method: 'POST',
      }),
    }),
    getMachineSupportedVersion: builder.query<MachineSupportedVersionResponse, string | undefined>({
      query: (type) => ({
        url: MACHINE_SUPPORTED_VERSION + `${type ? '/' + type : ''}`,
        method: 'GET',
      }),
      transformResponse: ({ data }: GNXResponse<MachineSupportedVersionResponse>) => {
        return data;
      },
    }),
    updateMachineSupportedVersion: builder.mutation<void, { appType: string; version: string; reason: string }>({
      query: ({ appType, version, reason }) => ({
        url: applyParamsToUrl(MACHINE_SUPPORTED_VERSION_UPDATE, { appType, version }),
        method: 'POST',
        body: {
          reason,
        },
      }),
    }),
  }),
});

export const {
  useGetMachinesQuery,
  useGetMachineLogsQuery,
  useGetMachineLogFileQuery,
  useRequestLogsMutation,
  useGetMachineSupportedVersionQuery,
  useUpdateMachineSupportedVersionMutation,
} = MachineApi;
