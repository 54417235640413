import { createApi } from '@reduxjs/toolkit/query/react';

import { STOCK_SERVICE_URL } from 'config/DomainsConfig';
import {
  GET_PRODUCT_QUANTITY,
  GET_STOCKED_ITEMS,
  GET_SUPPLIER_LIST,
  UPDATE_STOCKED_ITEMS,
} from 'config/StockServiceEndpoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { PrintHouseProduct, ProductStockInfo, Supplier } from './Types';

export const StockApi = createApi({
  reducerPath: 'StockApi',
  tagTypes: ['stockItems'],
  baseQuery: fetchBaseQueryWrapper(STOCK_SERVICE_URL),
  endpoints: (builder) => ({
    getProductQuantity: builder.query<ProductStockInfo[], { printHouseUid: string[]; productUid: string[] }>({
      query: (data) => ({
        url: GET_PRODUCT_QUANTITY,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { productQuantity: ProductStockInfo[] } }) =>
        response?.data?.productQuantity || [],
    }),
    getStockItems: builder.query<PrintHouseProduct[], { printHouseUid: string[]; productUid?: string[] }>({
      query: (data) => ({
        url: GET_STOCKED_ITEMS,
        method: 'POST',
        body: data,
      }),
      providesTags: ['stockItems'],
      transformResponse: (response: { data?: { printHouseProduct: PrintHouseProduct[] } }) =>
        response?.data?.printHouseProduct || [],
    }),
    updateStockItem: builder.mutation<unknown, PrintHouseProduct[]>({
      query: (printHouseProduct) => ({
        url: UPDATE_STOCKED_ITEMS,
        method: 'POST',
        body: { printHouseProduct },
      }),
      invalidatesTags: (result) => (result ? ['stockItems'] : []), // only invalidate on success
    }),
    getSuppliersList: builder.query<Supplier[], void>({
      query: (data) => ({
        url: GET_SUPPLIER_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { supplier: Array<Supplier & { product: { uid: string }[] }> } }) =>
        response?.data?.supplier?.map((s) => ({
          ...s,
          product: s?.product?.map((p: { uid: string }) => p.uid) || [],
        })) || [],
    }),
  }),
});

export const {
  useLazyGetProductQuantityQuery,
  useGetStockItemsQuery,
  useGetSuppliersListQuery,
  useUpdateStockItemMutation,
} = StockApi;
