import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IToast } from './Types';

const initialState: IToast = {
  type: null,
  content: null,
};

const toastReducerSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<IToast>) => {
      state.type = action.payload.type;
      state.content = action.payload.content;
      state.code = action.payload.code;
    },
  },
});

export default toastReducerSlice.reducer;

export const { showToast } = toastReducerSlice.actions;
