import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_PROXY_URL } from 'config/DomainsConfig';
import { v1_CHANGE_PRINT_HOUSE, v1_CHANGE_SHIPPING_METHOD } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ChangePrintHouseV1Request, ChangeShippingMethodV1Request } from './Types';

export const V1PackageApi = createApi({
  reducerPath: 'V1PackageApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_PROXY_URL),
  endpoints: (builder) => ({
    v1ChangePrintHouse: builder.mutation<unknown, ChangePrintHouseV1Request>({
      query: (data) => ({
        url: v1_CHANGE_PRINT_HOUSE,
        method: 'POST',
        body: data,
      }),
    }),
    v1ChangeShippingMethodV1: builder.mutation<unknown, ChangeShippingMethodV1Request>({
      query: (data) => ({
        url: v1_CHANGE_SHIPPING_METHOD,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useV1ChangePrintHouseMutation, useV1ChangeShippingMethodV1Mutation } = V1PackageApi;
