export const GET_PRODUCTION_CAPABILITY = '/v2/production-capability';
export const GET_PRODUCTION_CAPABILITY_LIST = '/v2/production-capability/list';
export const GET_PRODUCTION_CAPABILITY_CATALOG_LIST_V2 = '/v2/production-capability/catalog/list';
export const GET_PRODUCTION_CAPABILITY_CATALOG_PRODUCTS = '/v2/production-capability/catalog/product';
export const GET_PRODUCT_PRODUCTION_CAPABILITY_STATE = '/v2/production-capability/check';
export const GET_PRODUCTION_CAPABILITY_PRODUCTS = '/v1/production-capability/products';
export const GET_PRODUCTION_CAPABILITY_CATALOG_COVERAGE = 'v2/production-capability/catalog/coverage';
export const GET_PRODUCTION_CAPABILITY_CATALOG_SUPPORTED_PRINT_HOUSES = 'v2/production-capability/print-house/capable';
export const GET_PRODUCTION_CAPABILITY_CATALOG_STATUS = 'v2/production-capability/group/status';
export const GET_PARTNER_SKU = 'v2/production-capability/print-house/sku';
export const GET_PRODUCT_STATUS_CSV = '/v2/production-capability/product-status/download';
