import { createApi } from '@reduxjs/toolkit/query/react';

import { IMPOSITION_URL } from 'config/DomainsConfig';
import {
  GET_PRINT_PLATE,
} from "config/ImpositionEndPoints";
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

export const ImpositionServicePrintPlatesApi = createApi({
  reducerPath: 'ImpositionServicePrintPlatesApi',
  baseQuery: fetchBaseQueryWrapper(IMPOSITION_URL),
  endpoints: (builder) => ({
    getCombinePlateObject: builder.query<object, number>({
      query: (impositionServicePrintPlateId) => ({
        url: `${GET_PRINT_PLATE}/${impositionServicePrintPlateId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCombinePlateObjectQuery,
} = ImpositionServicePrintPlatesApi;
