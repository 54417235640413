import { createSlice } from '@reduxjs/toolkit';

import { IState } from './Types';

const initialState: IState = {
  printHouseName: '',
  isLoading: false,
};

const selectprintHousesSlice = createSlice({
  name: 'selectPrintHouses',
  initialState,
  reducers: {
    printHousesName: (state, action) => {
      state.printHouseName = action.payload;
      state.isLoading = false;
    },
  },
});

export default selectprintHousesSlice.reducer;

const { printHousesName } = selectprintHousesSlice.actions;

export const setPrintHouseName: Function = (printHouseName) => async (dispatch) => {
  dispatch(printHousesName(printHouseName));
};

export const removePrintHouseName: Function = (printHouseName) => async (dispatch) => {
  dispatch(printHousesName(''));
};
