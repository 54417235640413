import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryGNXWrapper } from 'core/Helpers/baseQuery';
import { applyParamsToUrl } from 'core/Helpers/url';
import { REACT_APP_GNX_URL } from 'config/DomainsConfig';

import { COMMANDS_LIST, COMMAND } from 'config/GNXEndPoints';

import { GNXResponse, Command, CommandResponse } from './Types';

export const CommandsApi = createApi({
  reducerPath: 'CommandsApi',
  baseQuery: fetchBaseQueryGNXWrapper(REACT_APP_GNX_URL),
  endpoints: (builder) => ({
    getCommands: builder.query<Command[], void>({
      query: () => ({
        url: COMMANDS_LIST,
        method: 'GET',
      }),
      transformResponse: ({ data }: GNXResponse<Command[]>) => data,
    }),
    runCommand: builder.mutation<CommandResponse, { id: string }>({
      query: ({ id }) => ({
        url: applyParamsToUrl(COMMAND, { id }),
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetCommandsQuery, useRunCommandMutation } = CommandsApi;
