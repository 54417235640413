import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  GET_PARTNER_DELIVERY_TIME_PADDING,
  UPDATE_PARTNER_DELIVERY_TIME__TEMP_PADDING,
} from 'config/GelatoNetworkEndPoints';
import { showToast } from 'store/Toast/Toast';

import {
  PartnerDeliveryTimeTempPaddingRequest,
  PartnerDeliveryTimePaddingResponse,
  DeliveryTimePaddingUpdateResponseData,
} from './Types';

export const DeliveryTimeAPI = createApi({
  reducerPath: 'DeliveryTimeAPI',
  tagTypes: ['DeliveryTime'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getPartnerDeliveryTimePadding: builder.query<PartnerDeliveryTimePaddingResponse, string>({
      query: (printHouseUid) => ({
        url: GET_PARTNER_DELIVERY_TIME_PADDING,
        method: 'POST',
        body: { printHouseUid },
      }),
      providesTags: ['DeliveryTime'],
      transformResponse: (resp: { data: PartnerDeliveryTimePaddingResponse }) => resp.data,
    }),

    updatePartnerDeliveryTimeTempPadding: builder.mutation<
      DeliveryTimePaddingUpdateResponseData,
      PartnerDeliveryTimeTempPaddingRequest
    >({
      query: (data) => ({
        url: UPDATE_PARTNER_DELIVERY_TIME__TEMP_PADDING,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['DeliveryTime'],
      transformResponse: (resp: { data: DeliveryTimePaddingUpdateResponseData }) => resp.data,
    }),
  }),
});

export const { useGetPartnerDeliveryTimePaddingQuery, useUpdatePartnerDeliveryTimeTempPaddingMutation } =
  DeliveryTimeAPI;
