import { createSlice } from '@reduxjs/toolkit';

import { DashboardAPI } from './DashboardAPI';

const initialState = {
  pendingRequests: [],
  nonWorkingDays: [],
};

const dashboardDataList = createSlice({
  name: 'ProductsListSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(DashboardAPI.endpoints.getNonWorkingDays.matchFulfilled, (state, { payload }) => {
      state.nonWorkingDays = payload.data.productionDay;
    });
    builder.addMatcher(DashboardAPI.endpoints.getPendingRequests.matchFulfilled, (state, { payload }) => {
      state.pendingRequests = payload.data.productionDay;
    });
  },
});

export default dashboardDataList.reducer;
