// !!!! TODO: All roles should be replaced with scopes !!!!

//ROLES
export const KEYCLOAK_ROLE_PRODUCTION_ADMIN = 'urn:*:roles:gelato:production-admin';
export const KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT = 'urn:*:roles:gelato:production-admin-agreement';
export const KEYCLOAK_ROLE_PRODUCTION_DEVELOPER = 'urn:*:roles:gelato:production-developer';
export const KEYCLOAK_ROLE_CUSTOMER_SUPPORT = 'urn:*:roles:gelato:customer-support';
export const KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER = 'urn:*:roles:gelato:customer-support-manager';

//SCOPES
export const KEYCLOAK_ROLE_CONNECT_API_SYNC_READ_WRITE = 'urn:production:scopes:apparel-ui-connect-api-sync:rw';
export const KEYCLOAK_ROLE_APPAREL_SKU_READ_WRITE = 'urn:production:scopes:apparel-ui-sku-management:rw';
export const KEYCLOAK_ROLE_CONNECT_API_SYNC_READ_ONLY = 'urn:production:scopes:apparel-ui-connect-api-sync:ro';
export const KEYCLOAK_ROLE_APPAREL_SKU_READ_ONLY = 'urn:production:scopes:apparel-ui-sku-management:ro';
export const KEYCLOAK_ROLE_AGREEMENT_READ_ONLY = 'urn:production:scopes:agreement_version_management:ro';
export const KEYCLOAK_ROLE_AGREEMENT_READ_WRITE = 'urn:production:scopes:agreement_version_management:rw';

export const KEYCLOAK_ERROR_REPORT_READ_ONLY = 'urn:error-report:scopes:reports:ro';
export const KEYCLOAK_ERROR_REPORT_READ_WRITE = 'urn:error-report:scopes:reports:rw';

export const KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY = 'urn:production:scopes:manage-gnx-settings:ro';
export const KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE = 'urn:production:scopes:manage-gnx-settings:rw';

export const KEYCLOAK_ROLE_ROUTING_READ_ALL = 'urn:routing:scopes:*:ro';
export const KEYCLOAK_ROLE_ROUTING_ADMIN = 'urn:routing:scopes:*:admin';

export const KEYCLOAK_SCOPE_MANAGE_PRINT_PLATES_READ = 'urn:production:scopes:gnx-manage-print-plates:ro';
export const KEYCLOAK_SCOPE_MANAGE_PRINT_JOBS_READ = 'urn:production:scopes:gnx-manage-print-jobs:ro';
export const KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_READ = 'urn:production:scopes:gnx-manage-file-upload:ro';
export const KEYCLOAK_SCOPE_MANAGE_SLA_READ = 'urn:production:scopes:gnx-manage-sla:ro';

export const KEYCLOAK_SCOPE_MANAGE_PRINT_PLATES_WRITE = 'urn:production:scopes:gnx-manage-print-plates:rw';
export const KEYCLOAK_SCOPE_MANAGE_PRINT_JOBS_WRITE = 'urn:production:scopes:gnx-manage-print-jobs:rw';
export const KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_WRITE = 'urn:production:scopes:gnx-manage-file-upload:rw';
export const KEYCLOAK_SCOPE_MANAGE_SLA_WRITE = 'urn:production:scopes:gnx-manage-sla:rw';
