import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { GN_TRANSLATIONS_URL } from 'config/DomainsConfig';

export const TranslationsAPI = createApi({
  reducerPath: 'TranslationsAPI',
  baseQuery: fetchBaseQuery({ baseUrl: GN_TRANSLATIONS_URL }), // without auth header
  keepUnusedDataFor: 86400, // one day
  endpoints: (builder) => ({
    getTranslations: builder.query<Record<string, string>, void>({
      query: () => ({
        url: '/gn-admin-react-en-US.json',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetTranslationsQuery } = TranslationsAPI;
