import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IMenuItem } from 'core/Types';

interface IProps {
  item: IMenuItem;
  active: boolean;
}

const MenuItem: FC<IProps> = ({ item, active }) => {
  return (
    <li className={`nav-item ${active && 'menu-open'}`}>
      <NavLink to={item.url} className="nav-link">
        <i className={`nav-icon fas ${item.icon}`} />
        <p className="text-nowrap">
          {item.name}
          {!!item.items.length && <i className="right fas fa-angle-left" />}
        </p>
      </NavLink>
      {!!item.items.length && (
        // @ts-ignore
        <ul className="nav nav-treeview">
          {item.subItemLabel}
          {item.items.map(({ name, url }, index) => (
            <li key={`${item.name}-menu-sub-${index}`} className="nav-item">
              <NavLink to={url} className="nav-link" activeClassName={active ? 'active' : ''}>
                <i className="far fa-circle nav-icon" />
                <p className="text-nowrap">{name}</p>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
