import { useMemo } from 'react';

import { PH_MANAGEMENT } from 'constants/index';
import { useAppSelector } from 'store/hooks';

const withSubitemLabels = (Menu) => {
  return ({ items }) => {
    const current_ph = useAppSelector((state) => state.selectPrintHouse.printHouseName);

    const subItemLabels = useMemo(
      () => ({
        [PH_MANAGEMENT]: (
          <li className="nav-item">
            <span className="nav-link pt-2 pd-2">
              Current Partner: <span className="font-weight-bold text-light">{current_ph}</span>
            </span>
          </li>
        ),
      }),
      [current_ph],
    );

    return <Menu items={items.map((item) => ({ ...item, subItemLabel: subItemLabels[item.name] }))} />;
  };
};

export default withSubitemLabels;
