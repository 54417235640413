import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import { printHouseIdentityContactApi } from './printHouseIdentityContactApi';
import { IState } from './Types';

const initialState: IState = {
  printHouseClients: {},
  partnerManagers: [],
};

const printHouseIdentityContactSlice = createSlice({
  name: 'printHouseIdentityContact',
  initialState,
  reducers: {
    addPartnerManagerUser: (state, action) => {
      state.partnerManagers.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      printHouseIdentityContactApi.endpoints.getPrintHouseClientId.matchFulfilled,
      (state, { payload }) => {
        state.printHouseClients = { ...state.printHouseClients, [payload.uid]: payload.uuid };
      },
    );
    builder.addMatcher(
      printHouseIdentityContactApi.endpoints.getpartnerManagersByIds.matchFulfilled,
      (state, { payload }) => {
        let newUsers = payload;
        if (state.partnerManagers.length) {
          newUsers = uniqBy([...state.partnerManagers, ...payload], 'id'); // remove dubplicate users if any
        }
        state.partnerManagers = newUsers;
      },
    );
  },
});

export const { addPartnerManagerUser } = printHouseIdentityContactSlice.actions;

export default printHouseIdentityContactSlice.reducer;
