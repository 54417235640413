import type { ISelectOption } from 'components/SimpleSelect/Types';
import { BANNER_TYPE, MESSAGE_TYPE } from 'store/NewsFeed/constants';

export const ALL_OPTION_FILTER_VALUE = 'all';
export const ALL_OPTION_FILTER: ISelectOption = { label: 'All', value: ALL_OPTION_FILTER_VALUE } as const;

export enum USER_ROLES {
  admin = 'urn:*:roles:production-partner:admin',
  manager = 'urn:*:roles:production-partner:manager',
  packager = 'urn:*:roles:production-partner:packaging-user',
  printOperator = 'urn:*:roles:production-partner:print-operator',
}

export const ROLES_LIST: ISelectOption[] = [
  { label: 'Packager', value: USER_ROLES.packager } as const,
  { label: 'Print Operator', value: USER_ROLES.printOperator } as const,
  { label: 'Manager', value: USER_ROLES.manager } as const,
  { label: 'Admin', value: USER_ROLES.admin } as const,
];

export enum AUDIENCE_SELECT_NAME {
  roles = 'roles',
  partners = 'printHouses',
}

export enum ACTIVE_TYPE {
  active = 'active',
  inactive = 'inactive',
}

export enum AUDIENCE_BANNER_TYPE {
  activate = 'activate',
  archive = 'archive',
  create = 'create',
}

export const ACTIVE_TYPE_LIST: ISelectOption[] = [
  { label: 'Active', value: ACTIVE_TYPE.active } as const,
  { label: 'Inactive', value: ACTIVE_TYPE.inactive } as const,
];

export const MESSAGE_TYPE_LIST: ISelectOption[] = [
  { label: 'Info', value: MESSAGE_TYPE.info } as const,
  { label: 'Feature', value: MESSAGE_TYPE.feature } as const,
  { label: 'Alert', value: MESSAGE_TYPE.alert } as const,
];

export const BANNER_TYPE_LIST: ISelectOption[] = [
  { label: 'Info', value: BANNER_TYPE.info } as const,
  { label: 'Warning', value: BANNER_TYPE.warning } as const,
  { label: 'Error', value: BANNER_TYPE.error } as const,
  { label: 'Fatal', value: BANNER_TYPE.fatal } as const,
];

export const NEWS_FEED_PAGE_PATH = '/news-feed';

export const NEWS_FEED_MESSAGE_LIST_PAGE_PATH = `${NEWS_FEED_PAGE_PATH}/messages`;
export const NEWS_FEED_MESSAGE_NEW_PAGE_PATH = `${NEWS_FEED_MESSAGE_LIST_PAGE_PATH}/message-new`;
export const NEWS_FEED_MESSAGE_DETAILS_PAGE_PATH = `${NEWS_FEED_MESSAGE_LIST_PAGE_PATH}/message`;

export const NEWS_FEED_BANNER_LIST_PAGE_PATH = `${NEWS_FEED_PAGE_PATH}/banners`;
export const NEWS_FEED_BANNER_NEW_PAGE_PATH = `${NEWS_FEED_BANNER_LIST_PAGE_PATH}/banner-new`;
export const NEWS_FEED_BANNER_DETAILS_PAGE_PATH = `${NEWS_FEED_BANNER_LIST_PAGE_PATH}/banner`;

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_SERVER_FILTER_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const DETAILS_DATE_FORMAT = 'LLLL';

export const NOT_ADDED = 'Not added';

export const QUERY_FROM_NAME = 'from';

export const NOTIFICATION_APP_TYPES = {
  packaging: 'packaging app (desktop/web/pwa)',
  'gelato-os': 'Gelato OS app (mobile)',
  all: 'all',
};
