import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { PRODUCTION_SUMMARY_SERVICE_URL } from 'config/DomainsConfig';
import {
  GET_EVENTS,
  GET_GCW_INVOICE_SUMMARY,
  GET_INVOICES,
  GET_INVOICE_TRANSACTIONS,
  GET_PACKAGE_TRANSACTIONS,
  GET_PRINTJOB_TRANSACTIONS,
  GET_RECALCULATION_LOG,
  GET_TRANSACTION_TABLES,
} from 'config/ProductionSummaryServiceEndpoints.ts';

import {
  ClientInvoiceSummaryData,
  EventsRequest,
  EventsResponse,
  InvoicesRequest,
  InvoicesResponse,
  InvoiceTransactionsRequest,
  InvoiceTransactionsResponse,
  LineTransactionTablesRequest,
  LineTransactionTablesResponse,
  PackageTransactionsRequest,
  PackageTransactionsResponse,
  PartnerClientInvoiceSummaryRequest,
  PrintJobTransactionsRequest,
  PrintJobTransactionsResponse,
  RecalculationsLogRequest,
  RecalculationsLogResponse,
} from './Types';
import {
  buildGCWInvoiceSummaryRequest,
  buildSearchStringForRequest,
  buildSearchStringForTransactionsRequest,
} from './helpers';

export const ProductionSummaryAPI = createApi({
  reducerPath: 'ProductionSummaryAPI',
  baseQuery: fetchBaseQueryWrapper(PRODUCTION_SUMMARY_SERVICE_URL),
  endpoints: (builder) => ({
    getEvents: builder.query<EventsResponse['data'], EventsRequest>({
      query: (data) => ({
        url: `${GET_EVENTS}?${buildSearchStringForRequest(data)}`,
        method: 'GET',
      }),
      transformResponse: (resp: EventsResponse) => resp.data,
    }),
    getPrintjobTransactions: builder.query<PrintJobTransactionsResponse['data'], PrintJobTransactionsRequest>({
      query: (data) => ({
        url: `${GET_PRINTJOB_TRANSACTIONS}?${buildSearchStringForRequest(data)}`,
        method: 'GET',
      }),
      transformResponse: (resp: PrintJobTransactionsResponse) => resp.data,
    }),
    getPackageTransactions: builder.query<PackageTransactionsResponse['data'], PackageTransactionsRequest>({
      query: (data) => ({
        url: `${GET_PACKAGE_TRANSACTIONS}?${buildSearchStringForRequest(data)}`,
        method: 'GET',
      }),
      transformResponse: (resp: PackageTransactionsResponse) => resp.data,
    }),
    getRecalculationsLog: builder.query<RecalculationsLogResponse['data'], RecalculationsLogRequest>({
      query: (data) => ({
        url: `${GET_RECALCULATION_LOG}?${buildSearchStringForRequest(data)}`,
        method: 'GET',
      }),
      transformResponse: (resp: RecalculationsLogResponse) => resp.data,
    }),
    getInvoices: builder.query<InvoicesResponse['data'], InvoicesRequest>({
      query: (data) => ({
        url: GET_INVOICES,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: InvoicesResponse) => resp.data,
    }),
    getInvoiceTransactions: builder.query<InvoiceTransactionsResponse['data'], InvoiceTransactionsRequest>({
      query: (data) => ({
        url: `${GET_INVOICE_TRANSACTIONS}?${buildSearchStringForRequest(data)}`,
        method: 'GET',
      }),
      transformResponse: (resp: InvoiceTransactionsResponse) => resp.data,
    }),
    getLineTransactionTables: builder.query<LineTransactionTablesResponse, LineTransactionTablesRequest>({
      query: (data) => ({
        url: `${GET_TRANSACTION_TABLES}?${buildSearchStringForTransactionsRequest(data)}`,
        method: 'GET',
      }),
      transformResponse: (resp: { data: LineTransactionTablesResponse }) => resp.data,
    }),
    getGCWInvoiceSummary: builder.query<ClientInvoiceSummaryData[], PartnerClientInvoiceSummaryRequest>({
      query: (data) => ({
        url: `${GET_GCW_INVOICE_SUMMARY}?${buildGCWInvoiceSummaryRequest(data)}`,
        method: 'GET',
      }),
      transformResponse: (resp: { data: { responses: ClientInvoiceSummaryData[] } }) => resp?.data?.responses || [],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetInvoicesQuery,
  useGetPrintjobTransactionsQuery,
  useGetPackageTransactionsQuery,
  useGetRecalculationsLogQuery,
  useGetInvoiceTransactionsQuery,
  useGetLineTransactionTablesQuery,
  useGetGCWInvoiceSummaryQuery,
} = ProductionSummaryAPI;
