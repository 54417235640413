import { lazy } from 'react';

import { KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER } from 'config/KeycloackScopes';

import {
  NEWS_FEED_BANNER_LIST_PAGE_PATH,
  NEWS_FEED_BANNER_DETAILS_PAGE_PATH,
  NEWS_FEED_BANNER_NEW_PAGE_PATH,
  NEWS_FEED_MESSAGE_NEW_PAGE_PATH,
  NEWS_FEED_MESSAGE_LIST_PAGE_PATH,
  NEWS_FEED_MESSAGE_DETAILS_PAGE_PATH,
} from './constants';

const ProtectedRoute = lazy(() => import('core/ProtectedRoute'));

const MessageList = lazy(() => import('./containers/Messages/MessageList'));
const MessageNew = lazy(() => import('./containers/Messages/MessageNew'));
const MessageDetails = lazy(() => import('./containers/Messages/MessageDetails'));

const BannerList = lazy(() => import('./containers/Banners/BannerList'));
const BannerNew = lazy(() => import('./containers/Banners/BannerNew'));
const BannerDetails = lazy(() => import('./containers/Banners/BannerDetails'));

const ACCESS_SCOPES = [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER];

const newsFeedRoutes = [
  <ProtectedRoute
    path={`${NEWS_FEED_MESSAGE_DETAILS_PAGE_PATH}/:id`}
    component={MessageDetails}
    key="MessageDetails"
    scopes={ACCESS_SCOPES}
    title='"News Feed - Message Details'
  />,
  <ProtectedRoute
    path={NEWS_FEED_MESSAGE_NEW_PAGE_PATH}
    component={MessageNew}
    key="MessageNew"
    scopes={ACCESS_SCOPES}
    title="News Feed - Send Message"
  />,
  <ProtectedRoute
    path={NEWS_FEED_MESSAGE_LIST_PAGE_PATH}
    component={MessageList}
    key="MessageList"
    scopes={ACCESS_SCOPES}
    title="News Feed"
  />,
  <ProtectedRoute
    path={`${NEWS_FEED_BANNER_DETAILS_PAGE_PATH}/:id`}
    component={BannerDetails}
    key="BannerDetails"
    scopes={ACCESS_SCOPES}
    title="Banner Management - Banner Details"
  />,
  <ProtectedRoute
    path={NEWS_FEED_BANNER_NEW_PAGE_PATH}
    component={BannerNew}
    key="BannerNew"
    scopes={ACCESS_SCOPES}
    title="Banner Management - Create Banner"
  />,
  <ProtectedRoute
    path={NEWS_FEED_BANNER_LIST_PAGE_PATH}
    component={BannerList}
    key="BannerList"
    scopes={ACCESS_SCOPES}
    title="Banner Management"
  />,
];

export default newsFeedRoutes;
