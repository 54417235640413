import { login } from '../Authentication/Authentication';
import { store } from '../store';

export const UpdateTokenOnExpired = ({ getState }) => {
  return (next) => (action) => {
    const returnValue = next(action);
    if (getState().auth.keycloak !== null) {
      if (getState().auth.keycloak.isTokenExpired()) {
        store.dispatch(login(false));
      }
    }
    return returnValue;
  };
};
