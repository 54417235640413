import { useState } from 'react';

import { LOGISTICS_UI, GNX_APP_URL } from 'config/DomainsConfig';
import {
  KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
  KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
  KEYCLOAK_ROLE_PRODUCTION_ADMIN,
  KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
} from 'config/KeycloackScopes';
import { usePermissions } from 'hooks/useCheckPermissions';

const shortcuts = [
  {
    name: 'Prod. App: Machine Park',
    url: `${GNX_APP_URL}/machine-park`,
    scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
  },
  {
    name: 'Prod. App: Production Config.',
    url: `${GNX_APP_URL}/production-configuration`,
    scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
  },
  {
    name: 'Prod. App: Substrate Module',
    url: `${GNX_APP_URL}/substrates`,
    scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
  },
  {
    name: 'Routing: Order Simulation',
    url: `${LOGISTICS_UI}/routing-debug`,
    scopes: [
      KEYCLOAK_ROLE_PRODUCTION_ADMIN,
      KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
      KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
      KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
    ],
  },
];

const ShortcutsMenuItem = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((isOpen) => !isOpen);
  const styles = { display: open ? 'block' : 'none' };

  const { hasAnyRole } = usePermissions();
  const visibleShortcuts = shortcuts.filter((item) => hasAnyRole(item.scopes));

  return (
    <li className={`border-top border-secondary mt-3 nav-item ${open ? 'menu-open' : ''}`} onClick={toggle}>
      {/* TODO: use button with link styles */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="nav-link" style={{ cursor: 'pointer' }}>
        <p>
          Shortcuts
          <i className="fas fa-angle-left right" />
        </p>
      </a>
      <ul className="nav nav-treeview" style={styles}>
        {visibleShortcuts.map(({ name, url }, i) => (
          <li className="nav-item" key={i}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={url} className="nav-link" target="_blank" rel="noopener">
              <i className="fa fa-sm fa-external-link-alt mr-2" />
              <p>{name}</p>
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default ShortcutsMenuItem;
