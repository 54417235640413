import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_PROMISE_INFO } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { PromiseInfo } from './Types';

export const PromiseInfoApi = createApi({
  reducerPath: 'PromiseInfoApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getPromiseInfoByDeliveryPromiseUid: builder.query<PromiseInfo, string>({
      query: (deliveryPromiseUid) => ({
        url: GET_PROMISE_INFO,
        method: 'POST',
        body: { deliveryPromiseUid },
      }),
      transformResponse: (response: { data?: PromiseInfo }) => response.data,
    }),
  }),
});

export const { useGetPromiseInfoByDeliveryPromiseUidQuery } = PromiseInfoApi;
