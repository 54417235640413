// MPJ events
export const PJ_EVENT_HISTORY_VIEWED = 'Print Job History Viewed';
export const PJ_EVENT_HISTORY_VIEW_SWITCHED = 'Print Job History View Switched';
export const PROMISE_INFO_VIEWED = 'Promise Info Viewed';
export const ORDER_ISSUES_VIEWED = 'Order Issues Viewed';
export const DATE_DETAILS_VIEWED = 'Date Details Viewed';
export const INVOICE_LINK_OPENED = 'Package Invoice Link Opened';
export const TRACKING_LINK_OPENED = 'Package Tracking Link Opened';
export const AFTERSHIP_LINK_OPENED = 'Aftership Link Opened';
export const EDI_DOWNLOADED = 'EDI Downloaded';
export const PDF_DOWNLOADED = 'PDF Downloaded';
export const PDF_REPORT_DOWNLOADED = 'PDF Report Downloaded';
export const API_DASHBOARD_LINK_OPENED = 'API Dashboard Link Opened';
export const ORDER_SIMULATOR_LINK_OPENED = 'Order Simulator Link Opened';
export const MPJ_PRESET_SWITCHED = 'MPJ Preset Switched';
export const BUILD_AND_DOWNLOAD_PLATES_LOCAL = 'Build and Download Plates Local';
export const BUILD_AND_UPLOAD_PLATES_TRIGGERED = 'Build and Upload Plates to Partner Triggered';
export const PRINT_JOBS_DATA_EXPORTED = 'Print Jobs Data exported';
export const CUSTOMER_ADDRESS_UPDATED = 'Customer Address Updated';
export const TAG_ADDED_BULK = 'Bulk Tag Added';
export const TAG_REMOVED_BULK = 'Bulk Tag Removed';
export const TAG_ADDED = 'Tag Added';
export const TAG_REMOVED = 'Tag Removed';
export const PJ_STATUS_UPDATED = 'Print Jobs Status Updated';
export const CUSTOM_ATTR_PROD_OPTIONS_VIEWED = 'Custom Attributes/Production Options Viewed';
export const MPJ_LIMIT_SWITCHED = 'MPJ Limit Switched';
export const MPJ_BULK_EDI_USED = 'MPJ_BULK_EDI_USED';
export const MPJ_BULK_EDI_FAILED = 'MPJ BULK EDI Failed';

export const PACKAGE_REROUTED = 'Packages Rerouted';
export const ASYNC_PACKAGE_REROUTE = 'Async Packages Reroute';
export const PACKAGES_REROUTED_BYPASS_GUARDRAILS = 'Packages Rerouted - Bypass Guardrails';
export const SHIPPING_METHOD_UPDATED = 'Shipping Method Updated';
export const SHIPPING_METHOD_UPDATED_BYPASS_GUARDRAILS = 'Shipping Method Updated - Bypass Guardrails';

export const APP_CRASHED = 'App Crashed';
export const LOGGING_ERROR = 'Logging Error';

// Apparel Management events
export const GENERATE_MAPPING_TEMPLATE = 'Mapping template generated';
export const UPLOAD_CSV_FROM_HOME_SCREEN = 'Uploaded CSV for updates from home screen';
export const SETUP_NEW_SKUS = 'Clicked Setup New skus';
export const VIEWED_MANAGE_PRICING = 'Viewed Manage pricing';
export const CHECK_PORTFOLIO_COVERAGE = 'Checked Portfolio coverage';
export const CLICKED_ON_MORE_ACTIONS = 'Viewed more actions';
export const DELETE_SKU_MAPPING = 'Deleted SKU Mapping';
export const DOWNLOADED_INDIVIDUAL_MAPPING = 'Downloaded SKU mapping from edit section';
export const USED_FILTERS_ON_SKU_MAPPING_SECTION = 'Used sku column filtering on editing SKU values';
export const PARTNER_SKU_CHANGED = 'Changed partner SKU';
export const CHANGED_ALL_PRINT_LOCATION = 'Changed ALL print locations';
export const CHANGED_PRINT_LOCATION = 'Changed print locations';
export const DELTED_INDIVIDUAL_MAPPING = 'Delete mapping for the partner sku';
export const CHANGED_ON_DEMAND_TYPE = 'Changed on demand type for partner sku';
export const UPDATED_MAPPING = 'Updated sku mapping';
export const CANCELLED_CHANGES_IN_MAPPING = 'Clicked cancel while saving mapping';
export const CLOSED_EDIT_SKU_MODAL = 'Clicked on x to close the edit sku modal';
export const OPEN_CATEGORY_ACCORDION = 'Viewed the category';
export const CLOSED_CATEGORY_ACCORDION = 'Closed the category';
export const DELETED_PARTNER_SKU_MAPPING = 'Deleted partner sku mapping';

// Apparel Availability events

export const SWITCHED_TO_STOCK_MANAGER_TAB = 'Apparel Availability - Switched to stock manager tab';
export const SWITCHED_TO_OVERVIEW_TAB = 'Apparel Availability - Switched to overview tab';
export const SWITCHED_TO_ENABLE_DISABLE_TAB = 'Apparel Availability - Switched to enable/disable tab';
export const VIEWED_ALL_OOS_SKUS = 'Apparel Availability - Viewed ALL OOS skus inside overview';
export const VIEWED_ALL_DISABLED_SKUS = 'Apparel Availability - Viewed ALL disabled skus inside overview';
export const SELECTED_PRINT_HOUSE = 'Apparel Availability - Selected print house';
export const SEARCHED_PRINT_HOUSE_DATA = 'Apparel Availability - Searched print house to view apparel data';
export const RESET_STOCK_MANAGER_DATA = 'Apparel Availability - Reset stock manager searching values';
export const RESET_AVAILABILITY_TABLE_FILTERS = 'Apparel Availability - Reset apparel availability table filter values';
export const SEARCHED_AVAILABILITY_TABLE_FILTERS =
  'Apparel Availability - Searched apparel availability table filter values';
export const ENABLED_DISABLED_SKU_STATUS = 'Apparel Availability - Changed SKU disability status';
export const TOGGLED_SKU_STOCK_STATUS = 'Apparel Availability - Changed stock status';
export const USED_PAGINATION_TO_VIEW_DATA = 'Apparel Availability - Used pagination to view apparel data.';
export const SEARCH_USING_PARTNER_SKU = 'Apparel Availability - Used partner sku search to view apparel data.';
export const SEARCH_USING_PRODUCT_UIDS = 'Apparel Availability - Used product uids search to view apparel data.';

// Apparel Heatmap
export const SEARCH_USING_CATALOG_ID = 'Apparel Heatmap - Searched using catalog id';
export const SEARCH_USING_CATEGORY_UID = 'Apparel Heatmap - Searched using category id';
export const SELECTED_CATALOG_UIDS = 'Apparel Heatmap - Selected catalog uids';
export const SELECTED_PRODUCTS_FOR_HEATMAP_DATA = 'Apparel Heatmap - Selected products for heatmap data';
export const USED_ADVANCED_SEARCH = 'Apparel Heatmap - Opened advanced search';
export const USED_ADVANCED_SEARCH_PRINT_HOUSE_FILTER =
  'Apparel Heatmap - Opened advanced search and searched using print house';
export const USED_ADVANCED_SEARCH_PRODUCTION_HUB =
  'Apparel Heatmap - Opened advanced search and searched using production hub';
export const USED_ADVANCED_SEARCH_PRODUCTION_CLUSTER =
  'Apparel Heatmap - Opened advanced search and searched using production cluster';
export const SELECTED_PRINT_HOUSE_FILTERS = 'Apparel Heatmap - Selected print house filters';
export const SELECTED_PRODUCTION_HUBS = 'Apparel Heatmap - Selected production hubs';
export const SELECTED_PRODUCTION_CLUSTERS = 'Apparel Heatmap - Selected production clusters';
export const USED_SIZE_COLOUR_FILTER = 'Apparel Heatmap - Used size and color filter for filtering apparel data';
export const VIEWED_PRINT_LOCATION_HEATMAP = 'Apparel Heatmap - Viewed print location heat map';
export const VIEWED_STOCK_STATUS_HEATMAP = 'Apparel Heatmap - Viewed stock support heat map';
export const VIEWED_ALL_CATEGORIES = 'Apparel Heatmap - Viewed all categories';
export const CLOSED_ALL_CATEGORIES = 'Apparel Heatmap - Closed all categories';
export const VIEWED_HEATMAP_FOR_CATEGORY = 'Apparel Heatmap - Viewed a category for heatmap';
export const OPENED_CSV_MODAL = 'Apparel Heatmap - Opened download CSV modal';

// Apparel Mapping steps

export const CLICKED_ON_SETUP_NEW_SKUS = 'Apparel Mapping - Clicked on setup new skus';
export const OPENED_CATEGORY_TO_SELECT_SKUS = 'Apparel Mapping - Opened category to select skus';
export const CLOSED_CATEGORY_TO_SELECT_SKUS = 'Apparel Mapping - Closed category to select skus';

export const SELECTED_SKUS_FOR_MAPPING = 'Apparel Mapping - Selected SKUs for Mapping';
export const UN_SELECTED_SKUS_FOR_MAPPING = 'Apparel Mapping - Un Selected SKUs for Mapping';

export const COLLAPSE_ALL_ACCORDIONS = 'Apparel Mapping - Collapsed All accordions';
export const EXPAND_ALL_ACCORDIONS = 'Apparel Mapping - Expand All accordions';

export const MOVED_TO_STEP2 = 'Apparel Mapping - Moved to step 2 - Configure Grouped print locations';
export const SELECTED_GROUPED_PRINT_LOCATIONS = 'Apparel Mapping - Selected grouped print locations';
export const SELECTED_ALL_PRINT_LOCATIONS = 'Apparel Mapping - Selected All print locations';

export const MOVED_TO_STEP3 = 'Apparel Mapping - Moved to step 3 - Review Print Locations';
export const CHANGED_PRINT_LOCATIONS_INSIDE_REVIEW_PRINT_LOCATIONS =
  'Apparel Mapping - Changed print locations inside review print locations';
export const MOVED_TO_STEP4 = 'Apparel Mapping - Moved to step 4 - Map Partner skus and prices';
export const ADD_PARTNER_SKU_AND_PRICES_USING_UPLOAD_CSV =
  'Apparel Mapping - Added partner skus and prices using bulk upload';
export const ADD_PARTNER_SKU_AND_PRICES_MANUALLY = 'Apparel Mapping - Added partner skus and prices manually';
export const PARTNER_SUPPORTS_ON_DEMAND_SELECTION = 'Apparel Mapping - Selected on demand selection for partner';
export const PARTNER_DO_NOTSUPPORTS_ON_DEMAND_SELECTION =
  'Apparel Mapping - Un Selected on demand selection for partner';
export const SAVE_MAPPING = 'Apparel Mapping - Saved Mapping';
