import { createSlice } from '@reduxjs/toolkit';

import { ContactNotificationsApi } from './ContactNotificationsApi';
import { IState } from './Types';

const initialState: IState = {
  notificationGroupMembershipsMap: {},
};

const ContactNotificationsSlice = createSlice({
  name: 'ContactNotificationsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      ContactNotificationsApi.endpoints.getUsersNotificationSettings.matchFulfilled,
      (state, { payload }) => {
        state.notificationGroupMembershipsMap = payload.reduce(
          (acc, { identityUserUuid, notificationGroups }) => ({ ...acc, [identityUserUuid]: notificationGroups }),
          state.notificationGroupMembershipsMap,
        );
      },
    );
    builder.addMatcher(
      ContactNotificationsApi.endpoints.setUserNotificationSettings.matchFulfilled,
      (state, { meta }) => {
        const args = meta.arg.originalArgs;
        state.notificationGroupMembershipsMap = {
          ...state.notificationGroupMembershipsMap,
          [args.userUuid]: args.groups,
        };
      },
    );
  },
});

export default ContactNotificationsSlice.reducer;
