import { createSlice } from '@reduxjs/toolkit';

import { CapacityManagementApi } from './CapacityManagementAPI';
import { RoutingProductCatalogItem } from './Types';

const initialState = {
  routingCatalog: [] as RoutingProductCatalogItem[],
};

const CapacityManagementSlice = createSlice({
  name: 'CapacityManagementSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      CapacityManagementApi.endpoints.getRoutingProductCatalog.matchFulfilled,
      (state, { payload }) => {
        state.routingCatalog = payload;
      },
    );
  },
});

export default CapacityManagementSlice.reducer;
