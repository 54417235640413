import { createApi } from '@reduxjs/toolkit/query/react';
import { groupBy } from 'lodash';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_PARTNER_CLIENTS } from 'config/GelatoNetworkEndPoints';
import { GELATO_CLIENT_UID } from 'config/ClientId';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ClientsData, PartnerClient } from './Types';

export const PartnerClientsApi = createApi({
  reducerPath: 'PartnerClientsApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getPartnersClients: builder.query<ClientsData[], { printHouseUids: string[] }>({
      query: (data) => ({
        url: GET_PARTNER_CLIENTS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data?: { printHouseClients: PartnerClient[] } }) => {
        const allClients = response.data?.printHouseClients || [];

        const clients: ClientsData[] = [];
        const groupedClients = groupBy(allClients, 'printHouseUid');
        for (const printHouseUid in groupedClients) {
          clients.push({
            printHouseUid,
            printHouseClients: groupedClients[printHouseUid].sort((a, b) => {
              // if the client is the gelato client, it should always be first
              if (a?.uid === GELATO_CLIENT_UID) return -1;
              if (b?.uid === GELATO_CLIENT_UID) return 1;
              return a.name.localeCompare(b.name);
            }),
          });
        }

        clients.sort((a, b) => a.printHouseUid.localeCompare(b.printHouseUid));
        return clients;
      },
    }),
  }),
});

export const { useGetPartnersClientsQuery } = PartnerClientsApi;
