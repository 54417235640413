import { createSlice } from '@reduxjs/toolkit';

import { IState, UserInfo } from './Types';
import { UsersAPI } from './UsersApi';

const initialState: IState = {
  userNames: {},
  ids: [],
};

const UsersSlice = createSlice({
  name: 'UsersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(UsersAPI.endpoints.getUsersList.matchFulfilled, (state, { payload }) => {
      const newUsers = payload.reduce((acc, currentUser) => ({ ...acc, [currentUser.id]: currentUser.email }), {});
      const allUsers = { ...state.userNames, ...newUsers };
      const allUserIds = Object.keys(allUsers);
      return { userNames: allUsers, ids: allUserIds };
    });
  },
});

export default UsersSlice.reducer;
