import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryGNXWrapper } from 'core/Helpers/baseQuery';

import { REACT_APP_GNX_URL } from 'config/DomainsConfig';
import { SYNC_FEATURE_TOGGLES } from 'config/GNXEndPoints';

export const SyncFeatureTogglesApi = createApi({
  reducerPath: 'SyncFeatureTogglesApi',
  baseQuery: fetchBaseQueryGNXWrapper(REACT_APP_GNX_URL),
  endpoints: (builder) => ({
    syncFeatureToggles: builder.mutation<string, string>({
      query: (data: string) => ({
        url: `${SYNC_FEATURE_TOGGLES}${data ? `?only=${data}` : ''}`,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useSyncFeatureTogglesMutation } = SyncFeatureTogglesApi;
