import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector } from 'store/hooks';
import { showToast } from 'store/Toast/Toast';
import { IToast } from 'store/Toast/Types';
import ExpandableText from 'components/Text/ExpandableText';

import './index.scss';

const Toast = () => {
  const toastData = useAppSelector((state) => state.toast);
  const dispatch = useDispatch();

  useEffect(() => {
    const { type, content } = toastData;
    if (type && content) {
      notify(toastData);
    }
    return () => {
      dispatch(showToast({ type: null, content: null }));
    };
  }, [toastData, dispatch]);

  return (
    <>
      <ToastContainer newestOnTop={false} closeOnClick={false} pauseOnHover />
    </>
  );
};

export default Toast;

// we can not store react element in redux so we can use this directly to show toast with html content
export const notify = (data: { type: IToast['type']; content: string | ReactNode; code?: IToast['code'] }) => {
  const { type, content, code } = data;
  if (code === 422) {
    toast[type]('Validation error occurred', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  }
  if (code === 500) {
    toast[type]('Something went wrong', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  }

  let toastContent = <p className="text-break">{content}</p>;
  if (typeof content === 'string' && content.length > 100) {
    toastContent = <ExpandableText text={content} showLessEnabled />; // only incase of string with more than 100 chars
  }

  toast[type](toastContent, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
