import { createSlice } from '@reduxjs/toolkit';

import { ProductCatalogAPI } from './ProductCatalogAPI';
import { IState } from './Types';

const initialState: IState = {
  list: [],
};

const ProductCatalogSlice = createSlice({
  name: 'ProductCatalogSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(ProductCatalogAPI.endpoints.getProductCatalogList.matchFulfilled, (state, { payload }) => {
      state.list = payload;
    });
  },
});

export default ProductCatalogSlice.reducer;
