import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_SUITABLE_SHIPMENT_METHODS, GET_SUITABLE_SHIPMENT_SPLIT_METHODS } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import {
  PackageShipmentSplitMethodsResponseData,
  PackageShipmentMethodsData,
  PackageShipmentMethodsAPIResponseData,
} from './Types';

export const PackageShipmentMethodsApi = createApi({
  reducerPath: 'packageShipmentMethodsApi',
  tagTypes: ['ShipmentSplitMethods'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getShipmentSplitMethodsForPackages: builder.query<
      PackageShipmentSplitMethodsResponseData,
      { packageId: number; printHouseUid: string }[]
    >({
      query: (requests) => ({
        url: GET_SUITABLE_SHIPMENT_SPLIT_METHODS,
        method: 'POST',
        body: { requests },
      }),
      transformResponse: (response: { data?: PackageShipmentSplitMethodsResponseData }) => {
        return response?.data;
      },
      providesTags: ['ShipmentSplitMethods'],
    }),
    getShipmentMethodsForPackages: builder.query<
      PackageShipmentMethodsData[],
      { packageId: number; printHouseUid: string }[]
    >({
      query: (requests) => ({
        url: GET_SUITABLE_SHIPMENT_METHODS,
        method: 'POST',
        body: { requests },
      }),
      transformResponse: (response: { data?: PackageShipmentMethodsAPIResponseData }) => {
        const responses = response?.data?.responses || [];
        return responses.reduce<PackageShipmentMethodsData[]>(
          (acc, { packages, orderUid, errorMessage }) => [
            ...acc,
            ...packages.reduce<PackageShipmentMethodsData[]>(
              (pkgsAcc, pkg) => [...pkgsAcc, { ...pkg, orderUid, errorMessage }],
              [],
            ),
          ],
          [],
        );
      },
    }),
  }),
});

export const { useGetShipmentSplitMethodsForPackagesQuery, useGetShipmentMethodsForPackagesQuery } =
  PackageShipmentMethodsApi;
