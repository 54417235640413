import { createApi } from '@reduxjs/toolkit/query/react';

import { REACT_APP_GNX_URL } from 'config/DomainsConfig';
import { MESSAGES, MESSAGES_LIST, BANNERS_LIST, BANNERS } from 'config/GNXEndPoints';
import { fetchBaseQueryGNXWrapper } from 'core/Helpers/baseQuery';
import { showToast } from 'store/Toast/Toast';

import type {
  CreateMessagePayload,
  Banner,
  CreateBannerPayload,
  CreateMessageResponse,
  GetMessagesPayload,
} from 'store/NewsFeed/Types';
import type { IToast } from 'store/Toast/Types';

import { TAG } from './constants';
import { GetMessagesResponse, GetBannersResponse, GetBannersPayload } from './Types.d';

export const NewsFeedApi = createApi({
  reducerPath: 'NewsFeedApi',
  tagTypes: [TAG.messages, TAG.banners],
  baseQuery: fetchBaseQueryGNXWrapper(REACT_APP_GNX_URL),
  endpoints: (builder) => ({
    getMessages: builder.query<GetMessagesResponse['data'], GetMessagesPayload>({
      query: (body = {}) => ({
        url: MESSAGES_LIST,
        method: 'POST',
        body,
      }),
      transformResponse: ({ data }: GetMessagesResponse) => data,
      providesTags: () => [{ type: TAG.messages }],
    }),
    getPreviewMessage: builder.query<CreateMessageResponse, CreateMessagePayload>({
      query: (body) => ({
        url: MESSAGES,
        method: 'POST',
        body,
      }),
      transformResponse: ({ data }: { data: CreateMessageResponse }) => data,
    }),
    createMessage: builder.mutation<CreateMessageResponse, CreateMessagePayload>({
      query: (body) => ({
        url: MESSAGES,
        method: 'POST',
        body,
      }),
      transformResponse: ({ data }: { data: CreateMessageResponse }) => data,
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { devices, users } = data;
          const toastContent = `Created new message for ${devices} devices and ${users} users`;
          dispatch(showToast({ type: 'success', content: toastContent }));
        } catch (e) {
          if (e.error.data.error.message) {
            dispatch(showToast({ type: 'error', content: e.error.data.error.message, code: e.error.data.error.code }));
          }
        }
      },
      invalidatesTags: [{ type: TAG.messages }],
    }),
    getBanners: builder.query<GetBannersResponse['data'], GetBannersPayload>({
      query: (body) => ({
        url: BANNERS_LIST,
        method: 'POST',
        body: body || {},
      }),
      providesTags: () => [{ type: TAG.banners }],
      transformResponse: ({ data }: GetBannersResponse) => data,
    }),
    createBanner: builder.mutation<string, CreateBannerPayload>({
      query: (body) => ({
        url: BANNERS,
        method: 'POST',
        body,
      }),
      transformResponse: ({ data }: { data: { id: string } }) => data.id,
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const toastContent: IToast = { ...{ type: 'success', content: `Created new banner` } };
          dispatch(showToast(toastContent));
        } catch (e) {
          if (e.error.data.error.message) {
            const toastContent: IToast = {
              ...{ type: 'error', content: e.error.data.error.message, code: e.error.data.error.code },
            };
            dispatch(showToast(toastContent));
          }
        }
      },
      invalidatesTags: [{ type: TAG.banners }],
    }),
    archiveBanner: builder.mutation<null, string>({
      query: (id) => ({
        url: `${BANNERS}/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const toastContent: IToast = { ...{ type: 'success', content: `Banner archived` } };
          dispatch(showToast(toastContent));
        } catch (e) {
          if (e.error.data.error.message) {
            const toastContent: IToast = {
              ...{ type: 'error', content: e.error.data.error.message, code: e.error.data.error.code },
            };
            dispatch(showToast(toastContent));
          }
        }
      },
      invalidatesTags: [{ type: TAG.banners }],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useCreateMessageMutation,
  useCreateBannerMutation,
  useArchiveBannerMutation,
  useGetBannersQuery,
  useGetPreviewMessageQuery,
} = NewsFeedApi;
