import {
  EventsRequest,
  LineTransactionTablesRequest,
  PartnerClientInvoiceSummaryRequest,
  PrintJobTransactionsRequest,
  TransactionTablesPagination,
} from './Types';

export const buildSearchStringForRequest = (req: EventsRequest | PrintJobTransactionsRequest) => {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(req)) {
    if (key === 'pagination') {
      params.set('pagination.pageNumber', req.pagination.pageNumber.toString());
      params.set('pagination.perPage', req.pagination.perPage.toString());
      continue;
    }
    if (key === 'orderBy' && req.orderBy?.length) {
      req.orderBy.forEach(({ field, isDesc }, index) => {
        params.set(`orderBy.${index}.field`, field);
        params.set(`orderBy.${index}.isDesc`, isDesc ? 'true' : 'false');
      });
      continue;
    }
    if (Array.isArray(value)) {
      value.forEach((val) => params.append(key, val)); // to support multiple value with same key,  e.g.  id=1&id=2
      continue;
    }
    params.set(key, value);
  }

  return params.toString();
};

export const buildSearchStringForTransactionsRequest = (req: LineTransactionTablesRequest) => {
  const params = new URLSearchParams();

  const paginationKeys: (keyof TransactionTablesPagination)[] = [
    'eventPagination',
    'invoiceTransactionPagination',
    'packageTransactionPagination',
    'printJobTransactionPagination',
    'recalculationPagination',
  ];

  for (const [key, value] of Object.entries(req)) {
    if (key === 'lineFilters' && req.lineFilters?.length) {
      req.lineFilters.forEach(({ name, attributes }, i) => {
        params.set(`lineFilters.${i}.name`, name);
        attributes.forEach(({ key, value }, j) => {
          params.set(`lineFilters.${i}.attributes.${j}.key`, key);
          params.set(`lineFilters.${i}.attributes.${j}.value`, value);
        });
      });
      continue;
    }
    if (paginationKeys.includes(key as keyof TransactionTablesPagination)) {
      params.set(`${key}.pageNumber`, req[key].pageNumber.toString());
      params.set(`${key}.perPage`, req[key].perPage.toString());
      continue;
    }
    if (Array.isArray(value)) {
      value.forEach((val) => params.append(key, val)); // to support multiple value with same key,  e.g.  id=1&id=2
      continue;
    }
    params.set(key, value);
  }

  return params.toString();
};

export const buildGCWInvoiceSummaryRequest = (req: PartnerClientInvoiceSummaryRequest): string => {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(req)) {
    if (Array.isArray(value)) {
      value.forEach((val) => params.append(key, val)); // to support multiple value with same key,  e.g.  id=1&id=2
      continue;
    }
    params.set(key, value);
  }
  return params.toString();
};
