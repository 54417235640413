import { createSlice } from '@reduxjs/toolkit';

import Keycloak from 'keycloak-js';

import configuration from 'config/KeycloakConfig';
import { GELATO_CLIENT_ID } from 'config/ClientId';

import { StoreKeycloakInterface } from './Types';

const initialState: StoreKeycloakInterface = {
  token: '',
  keycloak: null,
  authenticated: false,
  userName: '',
  expireTime: 0,
  userId: '',
  isGelatoInternalUser: false,
};

const loginReducerSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStateGet: (state, action) => {
      state.token = action.payload.keycloak.token;
      state.keycloak = action.payload.keycloak;
      state.userName = action.payload.result.username;
      state.authenticated = action.payload.authenticated;
      state.expireTime = action.payload.keycloak.tokenParsed.exp;
      state.userId = action.payload.result.attributes.gelatoapi_user_id[0];
      state.isGelatoInternalUser = action.payload.result.attributes.gelatoapi_client_id.includes(GELATO_CLIENT_ID);
    },
  },
});

export default loginReducerSlice.reducer;

const { loginStateGet } = loginReducerSlice.actions;

const initAdapter = (keycloak, onLoadType) => {
  return keycloak.init({
    onLoad: onLoadType,
    checkLoginIframe: false,
  });
};

const updateTokenToState = (dispatch, data) => {
  dispatch(loginStateGet(data));
};

export const login: Function = (authenticated: boolean) => async (dispatch) => {
  if (authenticated === false) {
    const keycloak = new Keycloak(configuration);
    const authenticated = await initAdapter(keycloak, 'login-required');
    const result = await keycloak.loadUserProfile();
    updateTokenToState(dispatch, { result, authenticated, keycloak });
  }
};

export const updateToken: Function = (keycloak) => async (dispatch) => {
  await keycloak.updateToken(-1); // force referesh since we have timer already
  const result = await keycloak.loadUserProfile();
  updateTokenToState(dispatch, { result, authenticated: true, keycloak });
};
