import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  GET_ACTIVE_PARTNER_COUNTRIES,
  GET_PHMT_CONFIGURATION,
  GET_TIMEZONES_LIST,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ICountryData, ILocale } from './Types';

export const ConfigurationApi = createApi({
  reducerPath: 'ConfigurationApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getConfiguration: builder.query({
      query: (data) => ({
        url: GET_PHMT_CONFIGURATION,
        method: 'POST',
        body: data,
      }),
      keepUnusedDataFor: 86400, // one day
      transformResponse: (response: { data?: { country?: ICountryData[]; locale?: ILocale[] } }) => {
        const countries = response.data.country || [];
        const locale = response.data.locale.map(({ name, uid }) => ({ label: name, value: uid })) || [];
        return { countries, locale };
      },
    }),
    getActivePartnerCountries: builder.query<ICountryData[], null>({
      query: () => ({
        url: GET_ACTIVE_PARTNER_COUNTRIES,
        method: 'POST',
        body: {},
      }),
      transformResponse: (response: { data?: { country?: ICountryData[] } }) => {
        return response.data?.country || [];
      },
    }),
    getTimezonesList: builder.query<string[], null>({
      query: () => ({
        url: GET_TIMEZONES_LIST,
        method: 'POST',
        body: {},
      }),
      transformResponse: (response: { data?: { timezones: string[] } }) => {
        return response.data?.timezones || [];
      },
    }),
  }),
});

export const { useGetConfigurationQuery, useGetActivePartnerCountriesQuery, useGetTimezonesListQuery } =
  ConfigurationApi;
