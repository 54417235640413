import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { CREATE_AUDIT_LOGS, GET_AUDIT_LOGS, BATCH_CREATE_AUDIT_LOGS } from 'config/GelatoNetworkEndPoints';

import { IAuditLog, IAuditLogsRequest, IAuditLogsResponse } from './Types';

export const AuditLogsApi = createApi({
  reducerPath: 'AuditLogsApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  tagTypes: ['AuditLogs'],
  endpoints: (builder) => ({
    getAuditLogs: builder.query<IAuditLogsResponse, IAuditLogsRequest>({
      query: (data) => ({
        url: GET_AUDIT_LOGS,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'AuditLogs', id: 'LIST' }],
    }),
    createLog: builder.mutation<unknown, IAuditLog>({
      query: (data) => ({
        url: CREATE_AUDIT_LOGS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'AuditLogs', id: 'LIST' }],
    }),
    createLogs: builder.mutation({
      query: (data) => ({
        url: BATCH_CREATE_AUDIT_LOGS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'AuditLogs', id: 'LIST' }],
    }),
  }),
});

export const { useGetAuditLogsQuery, useCreateLogMutation, useCreateLogsMutation } = AuditLogsApi;
