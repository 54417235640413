import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

const authSelector = (state: RootState) => state.auth;

export const authenticatedUserIdSelector = createDraftSafeSelector(authSelector, (auth) => auth.userId);

export const userNameSelector = createDraftSafeSelector(authSelector, (auth) => auth.userName);

export const authTokenSelector = createDraftSafeSelector(authSelector, (auth) => auth.token);

export const gelatoInternalUserFlagSelector = createDraftSafeSelector(
  authSelector,
  (auth) => auth.isGelatoInternalUser,
);

export const isAuthenticatedFlagSelector = createDraftSafeSelector(authSelector, (auth) => auth.authenticated);
