import { useAppSelector } from 'store/hooks';

const Header = () => {
  const keycloak = useAppSelector((state) => state.auth.keycloak);
  const logout = () => {
    keycloak.logout({ redirectUri: window.location.origin });
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#!" role="button">
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#!" role="button">
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
          <li className="nav-item">
            <button className="btn nav-link" onClick={() => logout()}>
              Logout
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
