import { useCallback, useMemo } from 'react';

import { StoreKeycloakInterface } from 'store/Authentication/Types';
import { useAppSelector } from 'store/hooks';

export const usePermissions = () => {
  const auth: StoreKeycloakInterface = useAppSelector((state) => state.auth);
  const userRoles = auth.keycloak?.realmAccess?.roles;

  const hasAnyRole = useCallback(
    (rolesToFind: string[]) => {
      if (!rolesToFind?.length) return true; //  no roles defined means no need to check for roles
      // returns true if any role/scope is found
      return userRoles?.some((role) => rolesToFind.includes(role));
    },
    [userRoles],
  );

  return { hasAnyRole };
};

const useCheckPermissions = (rolesToFind: string[]) => {
  const { hasAnyRole } = usePermissions();
  return useMemo(() => hasAnyRole(rolesToFind), [hasAnyRole, rolesToFind]);
};

export default useCheckPermissions;
