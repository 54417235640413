import { createSlice } from '@reduxjs/toolkit';

import { ShipmentMethodApi } from './ShipmentMethodApi';
import type { IState } from './Types';

const initialState: IState = {
  shipmentMethods: [],
};

const shipmentMethodSlice = createSlice({
  name: 'shipmentMethod',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(ShipmentMethodApi.endpoints.getShipmentMethods.matchFulfilled, (state, { payload }) => {
      state.shipmentMethods = payload;
    });
  },
});

export default shipmentMethodSlice.reducer;
