import { useMemo } from 'react';

import { IMenuItem } from 'core/Types';
import { useAppSelector } from 'store/hooks';
import { PH_MANAGEMENT } from 'constants/index';
import { menuItems } from 'core/constants/menuItems';
import { IS_LIVE } from 'config/Environment';

import { usePermissions } from './useCheckPermissions';

const useMenuItems = () => {
  const current_ph = useAppSelector((state) => state.selectPrintHouse.printHouseName);
  const { hasAnyRole } = usePermissions();

  const shouldBeVisible = ({ scopes, hideOnLive }: typeof menuItems[0]): boolean => {
    if (hideOnLive && hideOnLive === IS_LIVE) return false;
    return hasAnyRole(scopes);
  };

  const visibleMenuItems = menuItems
    .filter(shouldBeVisible) // filter parent items
    .map((item) => ({
      ...item,
      items: item.items.filter(shouldBeVisible), // filter children
    }));

  const parseSubitems = useMemo(
    () => ({
      [PH_MANAGEMENT]: (items: Array<IMenuItem>) => {
        return items.map((item) => ({ ...item, url: item.url.replace(':printHouse', current_ph) }));
      },
    }),
    [current_ph],
  );

  return visibleMenuItems.map((item) => ({
    ...item,
    items: parseSubitems[item.name] ? parseSubitems[item.name](item.items) : item.items,
  }));
};

export default useMenuItems;
