import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  GET_PP_PRODUCT_MODELS,
  GET_PHMT_PRODUCTION_TIME,
  UPDATE_PHMT_STANDARD_PRODUCTION_TIME,
  UPDATE_PHMT_PARTNER_SPECIFIC_PRODUCT_PRODUCTION_TIME,
  UPDATE_PHMT_PARTNER_SPECIFIC_PRODUCT_PRODUCTION_TIME_TEMPORARY_PADDING,
  GET_CLIENT_SPECIFIC_PRODUCTION_TIME,
  UPDATE_CLIENT_SPECIFIC_PRODUCTION_TIME,
  GET_O2D_CONFIG,
  UPDATE_O2D_CONFIG,
  CUSTOMER_CUT_OFF_TIME,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { CustomerProductionTimeRule, OrderToDeliveryConfig, ProductModel, ProductionTimeData } from './Types';

export const ProductionTimeAPI = createApi({
  reducerPath: 'ProductionTimeAPI',
  tagTypes: [
    'ProductionTime',
    'ProductPlatformProductModels',
    'AuditLogs',
    'CustomerProductionTime',
    'O2DConfig',
    'CutOffTime',
  ],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getPlatformProductProductModels: builder.query<ProductModel[], { printHouseUid?: string }>({
      query: (data) => ({
        url: GET_PP_PRODUCT_MODELS,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ProductPlatformProductModels' }],
      transformResponse: (response: { data: { productModel: ProductModel[] } }) => response?.data?.productModel || [],
    }),
    getProductionTime: builder.query<ProductionTimeData, { printHouseUid: string }>({
      query: (data) => ({
        url: GET_PHMT_PRODUCTION_TIME,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ProductionTime' }],
      transformResponse: (response: { data: ProductionTimeData }) => response?.data,
    }),
    updateStandardProductionTime: builder.mutation({
      query: (data) => ({
        url: UPDATE_PHMT_STANDARD_PRODUCTION_TIME,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'ProductionTime' }, { type: 'AuditLogs', id: 'LIST' }] : []), // only invalidate on success
    }),
    updatePartnerSpecificProductProductionTime: builder.mutation({
      query: (data) => ({
        url: UPDATE_PHMT_PARTNER_SPECIFIC_PRODUCT_PRODUCTION_TIME,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'ProductionTime' }, { type: 'AuditLogs', id: 'LIST' }] : []), // only invalidate on success
    }),
    updatePartnerSpecificProductProductionTimePadding: builder.mutation({
      query: (data) => ({
        url: UPDATE_PHMT_PARTNER_SPECIFIC_PRODUCT_PRODUCTION_TIME_TEMPORARY_PADDING,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'ProductionTime' }, { type: 'AuditLogs', id: 'LIST' }] : []), // only invalidate on success
    }),
    getCustomersProductionTime: builder.query<CustomerProductionTimeRule[], { printHouseUid: string }>({
      query: (data) => ({
        url: GET_CLIENT_SPECIFIC_PRODUCTION_TIME,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'CustomerProductionTime' }],
      transformResponse: (response: { data: { productRules: CustomerProductionTimeRule[] } }) =>
        response?.data?.productRules || [],
    }),
    updateCustomerSpecificProductionTime: builder.mutation<
      { message: string },
      { printHouseUid: string; productRules: CustomerProductionTimeRule[] }
    >({
      query: (data) => ({
        url: UPDATE_CLIENT_SPECIFIC_PRODUCTION_TIME,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'CustomerProductionTime' }] : []), // only invalidate on success
    }),
    getO2DConfig: builder.query<OrderToDeliveryConfig[], void>({
      query: () => ({
        url: GET_O2D_CONFIG,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'O2DConfig' }],
      transformResponse: (response: { data: { orderToDeliveryRules: OrderToDeliveryConfig[] } }) =>
        response?.data?.orderToDeliveryRules || [],
    }),
    getCutOffTime: builder.query<
      {
        defaultCutoffTime: string;
        printHouseCutoff: {
          clientUid: string;
          cutoffTime: string;
        }[];
      },
      { printHouseUid: string }
    >({
      query: ({ printHouseUid }) => ({
        url: CUSTOMER_CUT_OFF_TIME.replace('{printHouseId}', printHouseUid),
        method: 'GET',
      }),
      providesTags: () => [{ type: 'CutOffTime' }],
      transformResponse: (response: { data }) => response?.data || [],
    }),

    updateCutOffTime: builder.mutation<
      unknown,
      {
        printHouseUid: string;
        clientUid: string;
        cutoffTime: string;
      }
    >({
      query: ({ printHouseUid, ...body }) => ({
        url: CUSTOMER_CUT_OFF_TIME.replace('{printHouseId}', printHouseUid),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'CutOffTime' }] : []),
    }),

    updateO2DConfig: builder.mutation<unknown, { list: OrderToDeliveryConfig[] }>({
      query: (data) => ({
        url: UPDATE_O2D_CONFIG,
        method: 'POST',
        body: { orderToDeliveryRules: data.list },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'O2DConfig' }] : []), // only invalidate on success
    }),
  }),
});

export const {
  useGetPlatformProductProductModelsQuery,
  useGetProductionTimeQuery,
  useUpdateStandardProductionTimeMutation,
  useUpdatePartnerSpecificProductProductionTimeMutation,
  useUpdatePartnerSpecificProductProductionTimePaddingMutation,
  useGetCustomersProductionTimeQuery,
  useUpdateCustomerSpecificProductionTimeMutation,
  useGetO2DConfigQuery,
  useUpdateO2DConfigMutation,
  useGetCutOffTimeQuery,
  useUpdateCutOffTimeMutation,
} = ProductionTimeAPI;
