import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASIC_PASSWORD, BASIC_USER, IS_STAGING } from 'config/Environment';

import type { RootState } from 'store/store';

const getToken = (getState: () => unknown): string => {
  const state = getState() as RootState;
  const kc = state.auth.keycloak;

  kc.updateToken(0);

  return kc.token;
};

export const fetchBaseQueryWrapper = (url: string) => {
  return fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken(getState);

      headers.set('authorization', `Bearer ${token}`);

      return headers;
    },
  });
};

export const fetchBaseQueryGNXWrapper = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken(getState);

      headers.set('X-Access-Token', token);

      if (IS_STAGING && BASIC_USER && BASIC_PASSWORD) {
        headers.set('authorization', `Basic ${btoa(BASIC_USER + ':' + BASIC_PASSWORD)}`);
      }

      return headers;
    },
  });
