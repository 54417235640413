import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  GET_NOTIFICATION_GROUPS,
  SEARCH_NOTIFICATION_GROUP_MEMBERSHIPS,
  SET_NOTIFICATION_GROUP_MEMBERSHIPS,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { NotificationGroup, NotificationGroupMemberships } from './Types';

export const ContactNotificationsApi = createApi({
  reducerPath: 'ContactNotificationsApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getNotificationGroups: builder.query<NotificationGroup[], null>({
      query: () => ({
        url: GET_NOTIFICATION_GROUPS,
        method: 'GET',
        keepUnusedDataFor: 12 * 60 * 60, // 12 hours
      }),
      transformResponse: (resp: { data: { notificationGroups: NotificationGroup[] } }) => {
        return resp?.data?.notificationGroups || [];
      },
    }),
    getUsersNotificationSettings: builder.query<NotificationGroupMemberships[], string[]>({
      query: (identityUserUuids) => ({
        url: SEARCH_NOTIFICATION_GROUP_MEMBERSHIPS,
        method: 'POST',
        body: { userUuids: identityUserUuids, pagination: { limit: 50, offset: 0 } },
      }),
      transformResponse: (resp: { data: { notificationGroupMemberships: NotificationGroupMemberships[] } }) => {
        return resp?.data?.notificationGroupMemberships || [];
      },
    }),
    setUserNotificationSettings: builder.mutation<unknown, { groups: NotificationGroup[]; userUuid: string }>({
      query: (data) => ({
        url: SET_NOTIFICATION_GROUP_MEMBERSHIPS,
        method: 'POST',
        body: { memberships: [{ userUuid: data.userUuid, notificationGroups: data.groups.map(({ id }) => ({ id })) }] },
      }),
    }),
  }),
});

export const {
  useGetNotificationGroupsQuery,
  useGetUsersNotificationSettingsQuery,
  useSetUserNotificationSettingsMutation,
} = ContactNotificationsApi;
