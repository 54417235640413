import { CSSProperties, FC } from 'react';

interface IProps {
  center?: boolean;
  vhHeight?: number;
  showBackDrop?: boolean;
  isContentLoader?: boolean;
  message?: string;
}

const backDropStyles: CSSProperties = {
  position: 'fixed',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',
  backgroundColor: 'rgba(255,255,255,0.7)',
  zIndex: '9999',
  height: '100vh',
};

const contentLoaderStyles: CSSProperties = {
  zIndex: 10,
  inset: 0,
  backgroundColor: '#ffffff63',

  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: '100%',
};

const centerStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const Loader: FC<IProps> = ({ center, vhHeight, showBackDrop, isContentLoader, message }) => {
  let styles = {};
  if (center) {
    styles = {
      ...centerStyles,
      height: `${vhHeight ?? '90'}vh`,
      ...(showBackDrop ? backDropStyles : {}),
    };
  }

  if (isContentLoader) {
    styles = {
      ...styles,
      ...contentLoaderStyles,
    };
  }
  return (
    <div style={styles}>
      <i className="fas fa-3x fa-spinner fa-pulse" />
      <p>{message}</p>
    </div>
  );
};

Loader.defaultProps = {
  center: false,
};

export default Loader;
