import { createApi } from '@reduxjs/toolkit/query/react';

import { MACHINE_PARK_URL } from 'config/DomainsConfig';
import {
  GET_PARTNER_SKU,
  GET_PRODUCTION_CAPABILITY_CATALOG_STATUS,
  GET_PRODUCTION_CAPABILITY_CATALOG_SUPPORTED_PRINT_HOUSES,
  GET_PRODUCT_STATUS_CSV,
} from 'config/MachineParkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import {
  IApparelCatalogStatusCSVRequestBody,
  IApparelCatalogStatusRequestBody,
  IApparelCatalogStatusResponse,
  IApparelPrintHousesRequestBody,
  IGetPartnerSkuRequestBody,
  IPagination,
  IStatusObject,
} from './Types';

export const ApparelHeatmapApi = createApi({
  reducerPath: 'ApparelHeatmapAPI',
  tagTypes: ['ApparelCatalog'],
  baseQuery: fetchBaseQueryWrapper(MACHINE_PARK_URL),
  endpoints: (builder) => ({
    getApparelSupportedPrintHouses: builder.query<string[], IApparelPrintHousesRequestBody>({
      query: (data) => ({
        url: GET_PRODUCTION_CAPABILITY_CATALOG_SUPPORTED_PRINT_HOUSES,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ApparelCatalog', id: 'Printhouses' }],
      transformResponse: (response: { data?: { printHouse: string[] } }) => {
        return response.data?.printHouse;
      },
    }),
    getToteBagSupportedPrintHouses: builder.query<string[], IApparelPrintHousesRequestBody>({
      query: (data) => ({
        url: GET_PRODUCTION_CAPABILITY_CATALOG_SUPPORTED_PRINT_HOUSES,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ApparelCatalog', id: 'Printhouses' }],
      transformResponse: (response: { data?: { printHouse: string[] } }) => {
        return response.data?.printHouse;
      },
    }),
    getCatalogProductsStatus: builder.query<IApparelCatalogStatusResponse, IApparelCatalogStatusRequestBody>({
      query: (data) => ({
        url: GET_PRODUCTION_CAPABILITY_CATALOG_STATUS,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ApparelCatalog', id: 'ProductsStatus' }],
      transformResponse: (response: { data?: { group: IStatusObject[]; pagination: IPagination } }) => {
        return {
          data: response?.data?.group || [],
          total: response?.data?.pagination?.totalResult || 0,
        };
      },
    }),
    getPartnerSkus: builder.query<string[], IGetPartnerSkuRequestBody>({
      query: (data) => ({
        url: GET_PARTNER_SKU,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ApparelCatalog', id: 'PartnerSkus' }],
      transformResponse: (response: { data?: { sku: string[] } }) => {
        return response?.data?.sku;
      },
    }),
    getProductStatusCSV: builder.query<string, IApparelCatalogStatusCSVRequestBody>({
      query: (data) => ({
        url: GET_PRODUCT_STATUS_CSV,
        method: 'POST',
        body: data,
        responseHandler: (response) => response.text(),
      }),
      providesTags: () => [{ type: 'ApparelCatalog', id: 'PartnerSkus' }],
    }),
  }),
});

export const {
  useGetApparelSupportedPrintHousesQuery,
  useLazyGetCatalogProductsStatusQuery,
  useGetPartnerSkusQuery,
  useGetToteBagSupportedPrintHousesQuery,
  useLazyGetProductStatusCSVQuery,
} = ApparelHeatmapApi;
