import { createApi } from '@reduxjs/toolkit/query/react';

import { applyParamsToUrl } from 'core/Helpers/url';
import { GET_APPAREL_PRICES_LIST, GET_PRICE_MODELS_WITH_DATE, UPDATE_DRAFT_PRICING } from 'config/AgreementManagementEndpoints';
import { AGREEMENT_MANAGEMENT_URL } from 'config/DomainsConfig';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { IApparelPricingResponse, IPricingModel, IPricingObject, IPricingRequestBody, IUpdateDraftPriceRequest } from './Types';

export const ApparelPricingApi = createApi({
  reducerPath: 'PricingAPI',
  tagTypes: ['ApparelPricing'],
  baseQuery: fetchBaseQueryWrapper(AGREEMENT_MANAGEMENT_URL),
  endpoints: (builder) => ({
    getApparelPrices: builder.query<IPricingObject[], IPricingRequestBody>({
      query: (data) => ({
        url: GET_APPAREL_PRICES_LIST,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ApparelPricing', id: 'Prices' }],
      transformResponse: (response: { data?: { agreementParty: { value: IPricingObject[] }[] } }) => {
        return response.data?.agreementParty[0]?.value
      },
    }),
    updateDraftPricing: builder.mutation<IApparelPricingResponse, IUpdateDraftPriceRequest>({
      query: (data) => ({
        url: UPDATE_DRAFT_PRICING,
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: ['ApparelPricing'],
    }),
    getPriceModels: builder.query<IPricingModel, { partyUid: string; date: string }>({
      query: ({partyUid, date}) => ({
         url: applyParamsToUrl(GET_PRICE_MODELS_WITH_DATE, { partyUid, date }),
        method: 'POST',
        body: {},
      }),
      providesTags: () => [{ type: 'ApparelPricing', id: 'Pricing-models' }],
      transformResponse: (response: { data?: { startDate: string; endDate: string } }) => ({
        startDate: response.data?.startDate,
        endDate: response.data?.endDate,
      }),
    }),
  }),
});

export const { useGetApparelPricesQuery,useLazyGetApparelPricesQuery, useUpdateDraftPricingMutation, useGetPriceModelsQuery, useLazyGetPriceModelsQuery } = ApparelPricingApi;
