import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryGNXWrapper } from 'core/Helpers/baseQuery';

import { REACT_APP_GNX_URL } from 'config/DomainsConfig';
import {
  PRINT_PARTNER_ACTIVITY,
  PRINT_PARTNER_ACTIVITY_LOGS,
  PRINT_PARTNER_ACTIVITY_STATUS,
} from 'config/GNXEndPoints';
import { applyParamsToUrl } from 'core/Helpers/url';
import {
  DataFetchStatusResponse,
  PrintPartnerActivityListResponse,
  PrintPartnerActivityResponse,
} from 'containers/GNX/Types';

export const PrintPartnerActivityApi = createApi({
  reducerPath: 'PrintPartnerActivityApi',
  baseQuery: fetchBaseQueryGNXWrapper(REACT_APP_GNX_URL),
  endpoints: (builder) => ({
    getPrintPartnerActivity: builder.query<PrintPartnerActivityListResponse, void>({
      query: () => ({
        url: PRINT_PARTNER_ACTIVITY,
        method: 'GET',
      }),
      transformResponse: ({ data }: { data: PrintPartnerActivityListResponse }) => data,
    }),
    getActivityLogs: builder.query<PrintPartnerActivityResponse, string>({
      query: (partnerIdentifier: string) => ({
        url: applyParamsToUrl(PRINT_PARTNER_ACTIVITY_LOGS, { partnerIdentifier }),
        method: 'GET',
      }),
      transformResponse: ({ data }: { data: PrintPartnerActivityResponse }) => data,
    }),
    getActivityStatus: builder.query<DataFetchStatusResponse, void>({
      query: () => ({
        url: PRINT_PARTNER_ACTIVITY_STATUS,
        method: 'GET',
      }),
      transformResponse: ({ data }: { data: DataFetchStatusResponse }) => data,
    }),
  }),
});

export const { useGetPrintPartnerActivityQuery, useGetActivityLogsQuery, useGetActivityStatusQuery } =
  PrintPartnerActivityApi;
