export enum BANNER_TYPE {
  info = 'info',
  warning = 'warning',
  error = 'error',
  fatal = 'fatal',
}

export enum MESSAGE_TYPE {
  info = 'info',
  feature = 'feature',
  alert = 'alert',
}

export enum TAG {
  messages = 'NewsFeedMessages',
  banners = 'NewsFeedBanners',
}

export enum IDENTITY_LOCALE {
  en = 'en',
  fr = 'fr_FR',
  es = 'es',
  it = 'it',
  de = 'de_DE',
  se = 'se',
  no = 'nb_NO',
  jp = 'ja_JP',
  vi = 'vi',
  nl = 'nl_NL',
  da = 'da',
}
