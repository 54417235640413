import { createApi } from '@reduxjs/toolkit/query/react';

import { MACHINE_PARK_URL } from 'config/DomainsConfig';
import { GET_PRODUCTION_CAPABILITY_CATALOG_LIST_V2 } from 'config/MachineParkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ProductCatalog } from './Types';

export const ProductCatalogAPI = createApi({
  reducerPath: 'ProductCatalogAPI',
  tagTypes: ['ProductCatalog'],
  baseQuery: fetchBaseQueryWrapper(MACHINE_PARK_URL),
  endpoints: (builder) => ({
    getProductCatalogList: builder.query<ProductCatalog[], null>({
      query: () => ({
        url: GET_PRODUCTION_CAPABILITY_CATALOG_LIST_V2,
        method: 'POST',
        body: {},
      }),
      transformResponse: (resp: { data: { catalog: ProductCatalog[] } }) => {
        return resp.data?.catalog || [];
      },
      keepUnusedDataFor: 12 * 60 * 60, // 12 hours
    }),
  }),
});

export const { useGetProductCatalogListQuery } = ProductCatalogAPI;
