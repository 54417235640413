import { createSlice } from '@reduxjs/toolkit';

import { ApparelAvailabilityApi } from './ApparelAvailabilityApi';
import { IState } from './Types';

const initialState: IState = {
  mappedProducts: [],
  meta: {
    page: {
      number: 1,
      limit: 50,
      items: 50,
      totalItems: 0,
      expectTotalItems: true,
    },
  },
};

const ApparelAvailabilitySlice = createSlice({
  name: 'ApparelHeatmapSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      ApparelAvailabilityApi.endpoints.getApparelAvailabilityStatus.matchFulfilled,
      (state, { payload }) => {
        state.mappedProducts = payload.mappedProducts;
        state.meta = payload.meta;
      },
    );
  },
});

export default ApparelAvailabilitySlice.reducer;
