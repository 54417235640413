import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICategory, IManufacturerSKU, ISKUProduct } from 'containers/PrintHouseManagement/ApparelManagement/Types';
import { ICSVDataObject } from 'containers/PrintHouseManagement/ApparelManagement/containers/Pricing/components/PricingCSVDownloadAndUpload/Types';

import type { IPartnerSettingsRequest, IPartnerSettingsData, IState, IPartnerStatistics, IMappedProduct, IUnMappedProduct, ICategoryMappedPartnerSkus } from './Types';

import { ApparelManagementApi } from './ApparelManagementApi';

export const initialSyncStatus: IPartnerSettingsData = {
  isSyncEnabled: false,
  partnerUid: '',
  lastSync: '',
  pricingSyncUpdatedAt: '',
  printLocations: [],
};

const intitalPartnerStats: IPartnerStatistics = {
  totalGelatoSkus: 0,
  supportedSkus: 0,
};

const initialState: IState = {
  productsList: {},
  skusSelected: 0,
  activeStep: 0,
  skuMappingStrategy: '',
  printLocationsSelectedCount: 0,
  selectedProducts: {},
  manufactureSkusFromAPI: [],
  partnerProductsFromAPI: [],
  availablePartnerSkus: [],
  partnerSettings: initialSyncStatus,
  unSyncedChanges: [],
  selectedGroupPrintLocations: [],
  customPrintLocationsSelectedIds: [], // This is to persist print loactions selected in step 3 apart from grouped ones in step 2.
  partnerStatistics: intitalPartnerStats,
  mappedDataFromCSV: [],
  sortParams: null,
  allAvailableProductsForMapping: [],
  categoriesForSubstitution: [],
  showChangeReasonModal: false,
  toggleDataToUpdate: null,
  dataFromCSV: [],
  mappedManufacturerSkus: [],
  mappedProducts: []
};

const ApparelManagementSlice = createSlice({
  name: 'ApparelManagementSlice',
  initialState,
  reducers: {
    updateActiveStep: (state, action: PayloadAction<{ nextStep: number }>) => {
      const { nextStep } = action.payload;
      state.activeStep = nextStep;
    },
    updateSkuMappingStrategy: (state, action: PayloadAction<{ selected: string }>) => {
      const { selected } = action.payload;
      state.skuMappingStrategy = selected;
    },

    updatePrintLocationsCount: (state, action: PayloadAction<{ count: number }>) => {
      const { count } = action.payload;
      state.printLocationsSelectedCount = count;
    },

    setSelectedProducts: (state, action: PayloadAction<{ selected: ICategory }>) => {
      const { selected } = action.payload;
      state.selectedProducts = selected;
    },

    setAllAvailableProductsForMapping: (state, action: PayloadAction<{ products: ISKUProduct[] }>) => {
      const { products } = action.payload;
      state.allAvailableProductsForMapping = products;
    },

    updateSelectedGroupLocations: (state, action: PayloadAction<string[]>) => {
      state.selectedGroupPrintLocations = action.payload;
    },

    updateCustomPrintLocationObjectIds: (state, action: PayloadAction<string[]>) => {
      state.customPrintLocationsSelectedIds = action.payload;
    },

    updatePartnerSettingsToState: (state, action: PayloadAction<IPartnerSettingsRequest>) => {
      state.partnerSettings = { ...state.partnerSettings, ...action.payload };
    },

    storeCsvDataFromMapping: (state, action: PayloadAction<ICSVDataObject[]>) => {
      state.mappedDataFromCSV = action.payload;
    },

    setSortParams: (state, action: PayloadAction<{ param: string; direction: 'asc' | 'desc' }>) => {
      state.sortParams = action.payload;
    },

    setCategoriesForSubstitution: (state, action: PayloadAction<IManufacturerSKU[][]>) => {
      state.categoriesForSubstitution = action.payload;
    },

    resetPartnerSkuMapping: (state) => {
      state.partnerProductsFromAPI = [];
    },

    setShowChangeReasonModal: (state, action: PayloadAction<boolean>) => {
      state.showChangeReasonModal = action.payload;
    },

    setToggleDataToUpdate: (state, action: PayloadAction<IPartnerSettingsRequest>) => {
      state.toggleDataToUpdate = action.payload;
    },
    setCSVDataForAPI: (state, action: PayloadAction<ICSVDataObject[]>) => {
      state.dataFromCSV = action.payload;
    },
    setMappedManufacturerSkus: (state, action: PayloadAction<string[]>) => {
      state.mappedManufacturerSkus = action.payload;
    },
    setMappedProducts: (state, action: PayloadAction<IMappedProduct[]>) => {
      state.mappedProducts = action.payload;
    },
    setUnMappedProducts: (state, action: PayloadAction<IUnMappedProduct[]>) => {
      state.unMappedProducts= action.payload;
    },
     setAllCategories: (state, action: PayloadAction<ICategoryMappedPartnerSkus[]>) => {
      state.allCategories= action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(ApparelManagementApi.endpoints.getConnectApiSyncStatus.matchFulfilled, (state, { payload }) => {
      state.partnerSettings = payload;
    });
    builder.addMatcher(ApparelManagementApi.endpoints.getPartnerSkuStatistics.matchFulfilled, (state, { payload }) => {
      state.partnerStatistics = payload;
    });
    builder.addMatcher(ApparelManagementApi.endpoints.getPendingSkuUpdates.matchFulfilled, (state, { payload }) => {
      state.unSyncedChanges = payload;
    });
  },
});

export const {
  updateActiveStep,
  updateSkuMappingStrategy,
  updatePrintLocationsCount,
  setSelectedProducts,
  updateSelectedGroupLocations,
  updateCustomPrintLocationObjectIds,
  updatePartnerSettingsToState,
  storeCsvDataFromMapping,
  setSortParams,
  setAllAvailableProductsForMapping,
  setCategoriesForSubstitution,
  resetPartnerSkuMapping,
  setShowChangeReasonModal,
  setToggleDataToUpdate,
  setCSVDataForAPI,
  setMappedManufacturerSkus,
  setMappedProducts,
  setUnMappedProducts,
  setAllCategories
} = ApparelManagementSlice.actions;

export default ApparelManagementSlice.reducer;
