import { KEYCLOAK_URL } from './DomainsConfig';

const configuration = {
  realm: 'gelato-api',
  url: KEYCLOAK_URL + '/auth',
  sslRequired: 'external',
  clientId: 'production-dashboard',
  publicClient: true,
  confidentialPort: 0,
};
export default configuration;
