import { createSlice } from '@reduxjs/toolkit';

import { PrintJobApi } from './PrintJobApi';
import type { IState } from './Types';

const initialState: IState = {
  tags: [],
  statuses: [],
};

const printJobSlice = createSlice({
  name: 'printJob',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(PrintJobApi.endpoints.getPrintJobTags.matchFulfilled, (state, { payload }) => {
      state.tags = payload?.data?.tagUid;
    });
    builder.addMatcher(PrintJobApi.endpoints.getPrintJobStatuses.matchFulfilled, (state, { payload }) => {
      state.statuses = payload?.data?.printJobStatus;
    });
  },
});

export default printJobSlice.reducer;
