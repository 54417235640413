import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { CONNECT_API_URL } from 'config/DomainsConfig';
import {
  GET_APPAREL_AVAILABILITY,
  GET_APPAREL_AVAILABILITY_CHANGE_HISTORY,
  GET_APPAREL_AVAILABILITY_CSV_DOWNLOAD_LINK,
  GET_APPAREL_AVAILABILITY_STATS,
  TOGGLE_PARTNER_SKU_ENABLE_DISABLE_STATUS,
  TOGGLE_PARTNER_SKU_IN_STOCK_OUT_OF_STOCK_STATUS,
} from 'config/GelatoNetworkEndPoints';

import {
  IApparelAvailabilityCSVRequest,
  IApparelAvailabilityChangeHistoryRequest,
  IApparelAvailabilityRequest,
  IApparelAvailabilityResponse,
  IApparelPartnerStats,
  IChangeHistoryResponse,
  IToggleSkuStatus,
  IToggleStockStatus,
} from './Types';

export const ApparelAvailabilityApi = createApi({
  reducerPath: 'ApparelAvailabilityApi',
  baseQuery: fetchBaseQueryWrapper(CONNECT_API_URL),
  tagTypes: ['ApparelAvailability'],
  endpoints: (builder) => ({
    getApparelAvailabilityStatus: builder.query<IApparelAvailabilityResponse, IApparelAvailabilityRequest>({
      query: (data) => ({
        url: GET_APPAREL_AVAILABILITY,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: IApparelAvailabilityResponse) => {
        return response;
      },
      providesTags: () => [{ type: 'ApparelAvailability', id: 'status' }],
    }),
    getApparelAvailabilityCSVDownloadLink: builder.query<string, IApparelAvailabilityCSVRequest>({
      query: (data) => ({
        url: GET_APPAREL_AVAILABILITY_CSV_DOWNLOAD_LINK,
        method: 'POST',
        body: data,
        responseHandler: (response) => response.text(),
      }),
      providesTags: () => [{ type: 'ApparelAvailability', id: 'csv' }],
    }),
    getApparelAvailabilityChangeHistory: builder.query<
      IChangeHistoryResponse,
      IApparelAvailabilityChangeHistoryRequest
    >({
      query: (data) => ({
        url: GET_APPAREL_AVAILABILITY_CHANGE_HISTORY,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: IChangeHistoryResponse) => {
        return response;
      },
      providesTags: () => [{ type: 'ApparelAvailability', id: 'status' }],
    }),
    getApparelAvailabilityStats: builder.query<IApparelPartnerStats[], null>({
      query: () => ({
        url: GET_APPAREL_AVAILABILITY_STATS,
        method: 'GET',
      }),
      transformResponse: (response: { productAvailability: IApparelPartnerStats[] }) => {
        return response.productAvailability.map((val) => ({
          ...val,
          totalOOSPercentage: Math.trunc((val.totalOOS / val.total) * 100),
          totalDisabledPercentage: Math.trunc((val.totalDisabled / val.total) * 100),
        }));
      },
      providesTags: () => [{ type: 'ApparelAvailability', id: 'status' }],
    }),
    updatePartnerSkuStatus: builder.mutation<string, IToggleSkuStatus>({
      query: (data) => ({
        url: TOGGLE_PARTNER_SKU_ENABLE_DISABLE_STATUS,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (response: { message: string }) => {
        return response.message;
      },
    }),
    updatePartnerSkuStockStatus: builder.mutation<string, IToggleStockStatus>({
      query: (data) => ({
        url: TOGGLE_PARTNER_SKU_IN_STOCK_OUT_OF_STOCK_STATUS,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (response: { message: string }) => {
        return response.message;
      },
    }),
  }),
});

export const {
  useGetApparelAvailabilityStatusQuery,
  useUpdatePartnerSkuStatusMutation,
  useUpdatePartnerSkuStockStatusMutation,
  useGetApparelAvailabilityStatsQuery,
  useGetApparelAvailabilityChangeHistoryQuery,
  useLazyGetApparelAvailabilityCSVDownloadLinkQuery,
} = ApparelAvailabilityApi;
