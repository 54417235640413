import { IPrintJob } from './Types';

export const getUpdatedPrintjobs = (
  printJobs: IPrintJob[],
  newStatusUid?: string,
  deleteErrorTags?: boolean,
): IPrintJob[] => {
  let pjs = printJobs;
  if (newStatusUid) {
    pjs = printJobs.map((pj) => ({ ...pj, status: pj.status === 'canceled' ? pj.status : newStatusUid })); // update status if newStatusUid provided and previous status is not 'canceled'
  }
  if (deleteErrorTags) {
    pjs = pjs.map((pj) => ({ ...pj, tags: removeErrorTags(pj.tags) }));
  }
  return pjs;
};

const PROBLEM_TAG_PREFIX = 'PROBLEM';
const removeErrorTags = (tags: IPrintJob['tags']): IPrintJob['tags'] => {
  if (tags.some((t) => t.startsWith(PROBLEM_TAG_PREFIX))) {
    return tags.filter((t) => !t.startsWith(PROBLEM_TAG_PREFIX));
  }
  return tags;
};
