import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPrintJobStatusChangeMessage } from 'store/PrintJob/Types';
import { IShipmentMethod } from 'store/ShipmentMethod/Types';
import { PackageReroutingRequest } from 'store/Package/Types';

import { OrderApi } from './OrderApi';
import type { IState } from './Types';
import { getUpdatedPrintjobs } from './helper';

const initialState: IState = {
  orders: [],
  currentRequestId: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateReroutedPackages: (
      state,
      action: PayloadAction<{
        packages: (PackageReroutingRequest['package'][0] & { noShippingUpdate?: boolean })[];
        shipmentMethods: IShipmentMethod[];
        newStatusUid?: string;
        deleteErrorTags?: boolean;
      }>,
    ) => {
      const { packages, shipmentMethods, newStatusUid, deleteErrorTags } = action.payload;
      state.orders = state.orders.reduce((acc, order) => {
        const newOrder = {
          ...order,
          packages: order.packages.map((orderPackage) => {
            const reroutedPackage = packages.find(({ packageId }) => packageId === Number(orderPackage.id));
            if (reroutedPackage) {
              const shipmentMethod = shipmentMethods.find(({ uid }) => uid === reroutedPackage.shipmentMethodUid);
              return {
                ...orderPackage,
                ...(!reroutedPackage.noShippingUpdate && {
                  shipmentMethod: shipmentMethod?.title || reroutedPackage.shipmentMethodUid,
                  shipmentMethodUid: reroutedPackage.shipmentMethodUid,
                }),
                printHouseUid: reroutedPackage.printHouseUid,
                printJobs: getUpdatedPrintjobs(orderPackage.printJobs, newStatusUid, deleteErrorTags),
              };
            }
            return orderPackage;
          }),
        };
        return [...acc, newOrder];
      }, []);
    },
    updatePrintJobStatuses: (state, action: PayloadAction<IPrintJobStatusChangeMessage[]>) => {
      state.orders = state.orders.map((order) => ({
        ...order,
        packages: order.packages.map((orderPackage) => ({
          ...orderPackage,
          printJobs: orderPackage.printJobs.map((printJob) => {
            // eslint-disable-next-line max-nested-callbacks
            const printJobStatus = action.payload.find(({ id }) => printJob.id === id);
            if (printJobStatus) {
              return { ...printJob, status: printJobStatus.statusUid };
            }
            return printJob;
          }),
        })),
      }));
    },
    updatePrintJobTags: (
      state,
      action: PayloadAction<{ printJobIds: (string | number)[]; tags: string[]; tagAction: 'add' | 'remove' }>,
    ) => {
      const { printJobIds, tags, tagAction } = action.payload;
      const pjIds = printJobIds.map(String);

      state.orders = state.orders.map((order) => ({
        ...order,
        packages: order.packages.map((orderPackage) => ({
          ...orderPackage,
          printJobs: orderPackage.printJobs.map((printJob) => {
            if (pjIds.includes(printJob.id)) {
              const currentTags = printJob.tags;
              switch (tagAction) {
                case 'add':
                  const tagsToAdd = tags.filter((tag) => !currentTags.includes(tag)); // remove duplicates
                  return { ...printJob, tags: [...currentTags, ...tagsToAdd] }; // add tags
                case 'remove':
                  return { ...printJob, tags: currentTags.filter((tag) => !tags.includes(tag)) }; // remove tags
              }
            }
            return printJob;
          }),
        })),
      }));
    },
    setCurrentRequestId: (state, action) => {
      state.currentRequestId = action.payload;
    },
    clearOrders: (state) => {
      state.orders = initialState.orders;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(OrderApi.endpoints.getOrders.matchFulfilled, (state, { payload, meta }) => {
      if (meta.requestId !== state.currentRequestId) return; // as new request has been made,  ignore this now
      state.orders = payload?.data?.orders;
    });
  },
});

export default orderSlice.reducer;

export const { clearOrders, updateReroutedPackages, updatePrintJobStatuses, updatePrintJobTags, setCurrentRequestId } =
  orderSlice.actions;
