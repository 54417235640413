import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  PRODUCTION_DAYS_LIST,
  PRODUCTION_DAYS_CREATE_UPDATE,
  IMPORT_PUBLIC_HOLIDAYS,
  REMOVE_IMPORTED_HOLIDAYS,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { showToast } from '../Toast/Toast';
import {
  ImportPublicHolidayRequest,
  ImportPublicHolidayResponse,
  ProductionDay,
  ProductionDayCreateUpdateRequestBody,
  RevertedHolidayInfo,
} from './Types';

export const ProductionDaysApi = createApi({
  reducerPath: 'ProductionDaysApi',
  tagTypes: ['ProductionDays', 'AuditLogs'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getProductionDays: builder.query<ProductionDay[], { printHouseUid: string }>({
      query: (data) => ({
        url: PRODUCTION_DAYS_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { productionDayRequest: ProductionDay[] } }) => {
        return resp?.data?.productionDayRequest || [];
      },
      providesTags: () => [{ type: 'ProductionDays' }],
    }),
    createUpdateProductionDays: builder.mutation<unknown, ProductionDayCreateUpdateRequestBody>({
      query: (data) => ({
        url: PRODUCTION_DAYS_CREATE_UPDATE,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(showToast({ type: 'success', content: 'Updated Successfully' }));
        } catch (e) {
          const errorMessage = e?.error?.data?.error?.message || 'Operation failed';
          dispatch(showToast({ type: 'error', content: errorMessage }));
        }
      },
      invalidatesTags: [{ type: 'ProductionDays' }, { type: 'AuditLogs', id: 'LIST' }],
    }),
    importPublicHolidays: builder.mutation<ImportPublicHolidayResponse, ImportPublicHolidayRequest>({
      query: (data) => ({
        url: IMPORT_PUBLIC_HOLIDAYS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ProductionDays' }],
      transformResponse: (resp: { data: ImportPublicHolidayResponse }) => {
        return resp?.data;
      },
    }),
    revertImportedHolidays: builder.mutation<RevertedHolidayInfo[], { batchId: string; printHouseUids?: string[] }>({
      query: (data) => ({
        url: REMOVE_IMPORTED_HOLIDAYS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'ProductionDays' }],
      transformResponse: (resp: { data: { removedHolidays: RevertedHolidayInfo[] } }) => {
        return resp?.data?.removedHolidays || [];
      },
    }),
  }),
});

export const {
  useGetProductionDaysQuery,
  useCreateUpdateProductionDaysMutation,
  useImportPublicHolidaysMutation,
  useRevertImportedHolidaysMutation,
} = ProductionDaysApi;
