import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { SHIPMENT_API_URL } from 'config/DomainsConfig';
import { SHIPMENT_METHODS_LIST } from 'config/ShipmentApiEndPoints';

import { IShipmentMethod } from './Types';

export const ShipmentMethodApi = createApi({
  reducerPath: 'ShipmentMethodApi',
  tagTypes: ['ShipmentMethod'],
  baseQuery: fetchBaseQueryWrapper(SHIPMENT_API_URL),
  endpoints: (builder) => ({
    getShipmentMethods: builder.query<IShipmentMethod[], { locationUids?: string[] }>({
      query: (body) => ({
        url: SHIPMENT_METHODS_LIST,
        method: 'POST',
        body: { ...body, ...(body.locationUids && { printHouseUid: body.locationUids }) },
      }),
      transformResponse: (response: { data: { item?: IShipmentMethod[] } }) => {
        return response?.data?.item || [];
      },
      keepUnusedDataFor: 12 * 60 * 60, // 12 hours
    }),
  }),
});

export const { useGetShipmentMethodsQuery } = ShipmentMethodApi;
