export const TIME_FORMAT_24HOUR = 'HH:mm';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const NAMED_DATE_FORMAT = 'MMMM D, YYYY';

export const NAMED_DATE_TIME_FORMAT = 'MMM D, YYYY [at] HH:mm:ss';

export enum WeekDay {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const WeekdayName: { [key in WeekDay]: string } = {
  [WeekDay.MONDAY]: 'Monday',
  [WeekDay.TUESDAY]: 'Tuesday',
  [WeekDay.WEDNESDAY]: 'Wednesday',
  [WeekDay.THURSDAY]: 'Thursday',
  [WeekDay.FRIDAY]: 'Friday',
  [WeekDay.SATURDAY]: 'Saturday',
  [WeekDay.SUNDAY]: 'Sunday',
};
