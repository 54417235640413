import { createSlice } from '@reduxjs/toolkit';

import { ApparelHeatmapApi } from './ApparelHeatmapAPI';
import type { IState } from './Types';

export const initialHeatMapFilters = {
  selectedCategories: [],
  selectedProductionHubs: [],
  selectedProductionClusters: [],
  selectedPrintPartners: [],
  sizes: [],
  colors: [],
};

const initialState: IState = {
  selectedFilters: initialHeatMapFilters,
  availableCategories: [],
  printHouses: [],
  savedSupportedData: [],
  savedPrintLocationsData: [],
  selectedProductUids: [],
};

const ApparelHeatmapSlice = createSlice({
  name: 'ApparelHeatmapSlice',
  initialState,
  reducers: {
    updateSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    resetSelectedAdvanceFilters: (state) => {
      state.selectedFilters = {
        ...state.selectedFilters,
        selectedProductionClusters: [],
        selectedProductionHubs: [],
        selectedPrintPartners: [],
      };
    },
    // reset the filters on modal for CSV
    resetCsvFilters: (state) => {
      state.selectedFilters = {
        ...state.selectedFilters,
        selectedCategories: [],
        selectedPrintPartners: [],
      };
    },
    updateSelectedProductUids: (state, action) => {
      state.selectedProductUids = action.payload;
    },
    updateAvailableCategories: (state, action) => {
      state.availableCategories = action.payload;
    },
    saveLoadedSupportedData: (state, action) => {
      const isDataExists = state.savedSupportedData.find(({ catalogUid }) => catalogUid === action.payload.catalogUid);

      if (!isDataExists) {
        state.savedSupportedData.push(action.payload);
      }
    },
    saveLoadedPrintLocationsData: (state, action) => {
      const isDataExists = state.savedPrintLocationsData.find(
        ({ catalogUid }) => catalogUid === action.payload.catalogUid,
      );

      if (!isDataExists) {
        state.savedPrintLocationsData.push(action.payload);
      }
    },
    resetSavedData: (state) => {
      state.savedPrintLocationsData = [];
      state.savedSupportedData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ApparelHeatmapApi.endpoints.getApparelSupportedPrintHouses.matchFulfilled,
      (state, { payload }) => {
        state.printHouses = payload;
      },
    );
  },
});

export const {
  updateSelectedFilters,
  updateAvailableCategories,
  saveLoadedSupportedData,
  saveLoadedPrintLocationsData,
  resetSavedData,
  updateSelectedProductUids,
  resetSelectedAdvanceFilters,
  resetCsvFilters,
} = ApparelHeatmapSlice.actions;

export default ApparelHeatmapSlice.reducer;
