import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import {
  FILE_UPLOAD_SCHEDULER_LIST,
  FILE_UPLOAD_SCHEDULER_TOGGLE,
  FILE_UPLOAD_SCHEDULER_BATCH_UPDATE,
  FILE_UPLOAD_SCHEDULER_DELETE,
} from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { GELATO_CLIENT_UID } from 'config/ClientId';

import {
  DaySpecificFileUploadScheduleRule,
  FileUploadScheduleRule,
  FileUploadSchedulerListData,
  UploadScheduleBatch,
  UploadScheduleDeleteRequest,
  UploadScheduleToggleRequest,
} from './Types';

export const FileUploadSchedulerApi = createApi({
  reducerPath: 'FileUploadSchedulerApi',
  tagTypes: ['FileUploadScheduler'],
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    fileUploadSchedulerList: builder.query<FileUploadSchedulerListData, { printHouseUid: string }>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: {
        data: {
          printHouseUid: string;
          defaultUploadTime?: string;
          scheduleRule: (FileUploadScheduleRule | DaySpecificFileUploadScheduleRule)[];
        };
      }) => {
        const { scheduleRule, defaultUploadTime } = resp.data;
        const gelatoRules = scheduleRule.filter(
          ({ tenantCustomerUid }) => !tenantCustomerUid || tenantCustomerUid === GELATO_CLIENT_UID,
        );
        const nonGelatoRules = scheduleRule.filter(
          ({ tenantCustomerUid }) => tenantCustomerUid && tenantCustomerUid !== GELATO_CLIENT_UID,
        );
        return {
          defaultUploadTime: defaultUploadTime || '00:01',
          gelatoRules: {
            scheduleRule: gelatoRules.filter(
              ({ date }: DaySpecificFileUploadScheduleRule) => !date,
            ) as FileUploadScheduleRule[],
            daySpecificRule: gelatoRules.filter(
              ({ date }: DaySpecificFileUploadScheduleRule) => date,
            ) as DaySpecificFileUploadScheduleRule[],
          },
          nonGelatoRules: {
            scheduleRule: nonGelatoRules as FileUploadScheduleRule[],
          },
        };
      },
      providesTags: () => [{ type: 'FileUploadScheduler' }],
    }),
    fileUploadSchedulerToggle: builder.mutation<UploadScheduleBatch, UploadScheduleToggleRequest>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_TOGGLE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'FileUploadScheduler' }] : []), // only invalidate on success
      transformResponse: (resp: { data: UploadScheduleBatch }) => resp?.data,
    }),
    updateFileUploadScheduler: builder.mutation<UploadScheduleBatch, UploadScheduleBatch>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_BATCH_UPDATE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'FileUploadScheduler' }] : []), // only invalidate on success
      transformResponse: (resp: { data: UploadScheduleBatch }) => resp?.data,
    }),
    deleteFileUploadScheduler: builder.mutation<unknown, UploadScheduleDeleteRequest>({
      query: (data) => ({
        url: FILE_UPLOAD_SCHEDULER_DELETE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'FileUploadScheduler' }],
    }),
  }),
});

export const {
  useFileUploadSchedulerListQuery,
  useFileUploadSchedulerToggleMutation,
  useUpdateFileUploadSchedulerMutation,
  useDeleteFileUploadSchedulerMutation,
} = FileUploadSchedulerApi;
