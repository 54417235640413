import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';

import { GNX_APP_URL, GNX_APP_ALPHA_URL, GNX_APP_BETA_URL } from 'config/DomainsConfig';
import { useAppSelector } from 'store/hooks';
import { gelatoInternalUserFlagSelector, isAuthenticatedFlagSelector } from 'store/Authentication/selectors';
import { IS_ACCEPTANCE_TEST_ENV } from 'config/Environment';

type FeatureFlagGetter = <T>(flag: string, defaultValue?: T) => T;

interface IPartnerViewInfo {
  isInPartnerView: boolean;
  partnerUid: string;
  partnerTimezone: string;
  isGelatoInternalUser: boolean;
  getFeatureFlag: FeatureFlagGetter;
}

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
const allowedOrigins = [GNX_APP_URL, GNX_APP_ALPHA_URL, GNX_APP_BETA_URL, 'gelato:'];

const PartnerViewInfoContext = createContext<IPartnerViewInfo>({
  isInPartnerView: false,
  partnerUid: null,
  partnerTimezone: localTimezone,
  isGelatoInternalUser: false,
  getFeatureFlag: (flag, defaultValue) => defaultValue,
});

const isInIframe = () => {
  try {
    return window.self !== window.parent;
  } catch (e) {
    return false;
  }
};

const sendInitMessageToEmbedder = () => {
  window.parent.postMessage({ type: 'init' }, '*');
};

const PartnerViewInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const isEmbedded = isInIframe();
  const [isInPartnerView, setIsInPartnerView] = useState(isEmbedded && !IS_ACCEPTANCE_TEST_ENV); // acceptance test also run app in iframe so need to exclude it for tests to run
  const [partnerUid, setPartnerUid] = useState<string>(null);
  const [featureFlags, setFeatureFlags] = useState<Record<string, unknown>>(null);
  const [partnerTimezone, setPartnerTimezone] = useState<string>(localTimezone);

  const isGelatoInternalUser = useAppSelector(gelatoInternalUserFlagSelector);
  const isAuthenticated = useAppSelector(isAuthenticatedFlagSelector);

  const value: IPartnerViewInfo = useMemo(
    () => ({
      isInPartnerView,
      partnerUid,
      partnerTimezone,
      isGelatoInternalUser,
      getFeatureFlag: ((flag, defaultValue) => featureFlags?.[flag] ?? defaultValue) as FeatureFlagGetter,
    }),
    [isInPartnerView, partnerUid, partnerTimezone, isGelatoInternalUser, featureFlags],
  );

  const initRef = useRef(false);
  useEffect(() => {
    if (isInPartnerView && isAuthenticated && !initRef.current) {
      sendInitMessageToEmbedder();
      const id = setTimeout(() => {
        if (!initRef.current) {
          sendInitMessageToEmbedder();
        }
      }, 2000);
      return () => clearTimeout(id);
    }
  }, [isAuthenticated, isInPartnerView]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!allowedOrigins.includes(event.origin)) return; // ignore messages from other origins
      setIsInPartnerView(true);
      initRef.current = true;
      const { type, payload } = event.data;
      switch (type) {
        case 'state':
          setFeatureFlags(payload);
          break;
        case 'printHouseUid':
          setPartnerUid(payload);
          break;
        case 'printHouseTimezone':
          setPartnerTimezone(payload);
          break;
      }
    };
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <PartnerViewInfoContext.Provider value={value}>
      {
        // don't render children if authenticated and not in partner view and not gelato internal user i.e. some partner user is accessing UI directly
        isAuthenticated && !isInPartnerView && !isGelatoInternalUser ? <div>Access Denied!</div> : children
      }
    </PartnerViewInfoContext.Provider>
  );
};

const usePartnerViewInfo = () => {
  return useContext(PartnerViewInfoContext);
};

export { PartnerViewInfoProvider, usePartnerViewInfo };
