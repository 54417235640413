import { useEffect } from 'react';

import { eventIdentify } from 'eventTracking';

import { updateToken } from 'store/Authentication/Authentication';
import { useAppDispatch, useAppSelector } from 'store/hooks';

let lastUserId = null;

const RefreshTokenWrapper = () => {
  const auth = useAppSelector((state) => state.auth);
  const keycloak = auth.keycloak;
  const dispatch = useAppDispatch();
  const expireTime = auth.expireTime;

  useEffect(() => {
    const tokenExpireDelay = expireTime - Math.round(Date.now() / 1000) - 60; // refresh 60 seconds before actual expiry time to accommodate network delays
    const timeout = new CustomTimeout(() => {
      dispatch(updateToken(keycloak));
    }, tokenExpireDelay * 1000);
    return () => timeout.terminate();
  }, [keycloak, expireTime, dispatch]);

  useEffect(() => {
    if (auth?.authenticated && auth.userId !== lastUserId) {
      eventIdentify({ userId: auth.userId, userName: auth.userName });
      lastUserId = auth.userId;
    }
  }, [auth]);

  return null;
};

export default RefreshTokenWrapper;

// using web-worker timer to combat timer throttling by browsers, fallback to regular timeout if worker is not supported
// https://stackoverflow.com/questions/5927284/how-can-i-make-setinterval-also-work-when-a-tab-is-inactive-in-chrome
class CustomTimeout {
  private worker = null;
  private timeoutId = null;

  constructor(callback, timeoutMs: number) {
    try {
      const blob = new Blob([`setTimeout(() => postMessage(0), ${timeoutMs});`]);
      const workerScript = URL.createObjectURL(blob);
      this.worker = new Worker(workerScript);
      this.worker.onmessage = callback;
    } catch (error) {
      // Fallback to regular timeout if Worker is not supported or not allowed
      this.timeoutId = setTimeout(callback, timeoutMs);
    }
  }

  terminate() {
    if (this.worker) {
      this.worker.terminate();
    } else if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
